import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import zh from './zh'
import en from './en'
import zhf from './zhf'
Vue.use(VueI18n)

const messages = {
  en: {
    ...en,
  },
  zh: {
    ...zh,
  },
  zhf: {
    ...zhf,
  }
};
const i18n = new VueI18n({
  locale: localStorage.lang || 'en',
  messages,
});

console.log(i18n)

export default i18n;
