<template>
  <div class="parent" style="width: 100%">
    <div class="navContainer">
      <div class="banner">
        <div class="left">
          <ul>
            <li>
              <span>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
                  <!-- <svg:style type="text/css">.st0{fill:#404041}</svg:style> -->
                  <g id="XMLID_46763_">
                    <path id="XMLID_46768_" fill="#fff" class="st0" d="M24.5,17.8c0-3.1-3.1-5.5-6.9-5.5s-6.9,2.5-6.9,5.5s3.1,5.5,6.9,5.5c0.6,0,1.2-0.1,1.8-0.2
                      l3.7,1.6l-1.3-2.6C23.5,21.1,24.5,19.5,24.5,17.8z M15.5,17.4c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1
                      C16.6,17,16.1,17.4,15.5,17.4z M19.7,17.4c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1C20.7,17,20.3,17.4,19.7,17.4z"></path>
                    <path fill="#fff" id="XMLID_46764_" class="st0" d="M17.6,10.8c0.4,0,0.8,0,1.2,0.1c-0.7-3.2-4.1-5.6-8.2-5.6c-4.6,0-8.3,3.1-8.3,6.9
                      c0,2.2,1.2,4.2,3.2,5.4l-1.1,2.2l3-1.3c0.6,0.2,1.3,0.4,2,0.5c-0.1-0.4-0.2-0.9-0.2-1.3C9.3,14,13,10.8,17.6,10.8z M13.5,8.4
                      c0.6,0,1,0.5,1,1s-0.5,1-1,1s-1-0.5-1-1S12.9,8.4,13.5,8.4z M7.9,10.5c-0.6,0-1-0.5-1-1s0.5-1,1-1s1,0.5,1,1S8.5,10.5,7.9,10.5z"></path>
                  </g>
                </svg>
              </span>
            </li>
            <li>
              <span>
                <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1">
                  <g>
                    <title>Layer 1</title>
                    <g id="XMLID_23428_">
                    <path fill="#ffffff" d="m4.6,7.23782l10.4,8.4l10.4,-8.3l-20.8,0l0,-0.1zm-1.3,1.1l0,14.3l23.4,0l0,-14.3l-11.2,9c-0.3,0.3 -0.8,0.3 -1.1,0l-11.1,-9z" class="st0" id="XMLID_23431_"/>
                    </g>
                  </g>
                </svg>
              </span>
            </li>
          </ul>
        </div>
        <div class="right">
          <span><input type="text" :placeholder="$t('souxun')" value="" v-model="search" v-if="inputShow"></span>
          <span @click="onSearch"><i class="el-icon-search"></i></span>
          <span class="drop">
            <el-dropdown @command="checkoutLang">
              <span class="el-dropdown-link" >
               {{$t(drop[defaultLang-1].name)}}<i  class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="item.lang"
                  v-for="item in drop"
                  :key="item.lang">{{item.lang !== defaultLang ? $t(item.name) : ''}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </div>
      </div>
    </div>
    <div class="log">
      <div class="logFlex">
        <div class="logLeft">
<!--          <span class="img"><img src="../assets/imags/nav/40logo.svg" alt=""></span>-->
          <span class="img2"><img src="../assets/imags/nav/logo.png" alt=""></span>
        </div>
        <div :key="onlyKey" class="logRight">
          <el-menu ref="tab" style="height: 60px;display: flex" active-text-color="#0055a3" :default-active="tabDefault" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1" @click="index">{{$t('Home')}}</el-menu-item>
            <el-submenu index="2">
              <template slot="title">{{$t('AboutWillas')}}</template>
              <el-menu-item :index="'2-'+ item.id" @click="aboutGo(item)" v-for="item in aboutData" :key="item.id">{{$t(item.name)}}</el-menu-item>
            </el-submenu>
            <el-menu-item index="3" @click="makerHtml">{{$t('Manufacturers')}}</el-menu-item>
           <el-submenu index="4">
              <template slot="title">{{$t('ReferenceSolutions')}}</template>
              <el-submenu index="4-1">
                  <template slot="title">{{$t('AutomotiveInfotainment')}}</template>
                  <el-menu-item index="4-1-1">{{$t('BMS')}}</el-menu-item>
                  <el-menu-item index="4-1-2">{{$t('DZC')}}</el-menu-item>
                  <el-menu-item index="4-1-3">{{$t('FCFNV')}}</el-menu-item>
                  <el-menu-item index="4-1-4">{{$t('BLDC')}}</el-menu-item>
                  <el-menu-item index="4-1-5">{{$t('CMOS')}}</el-menu-item>
                  <el-menu-item index="4-1-6">{{$t('Digital')}}</el-menu-item>
              </el-submenu>
              <el-submenu index="4-2">
                  <template slot="title">{{$t('Internet')}}</template>
                  <section v-if="this.$i18n.locale != 'en'">
                    <el-menu-item index="4-2-1">{{$t("Intelligentindustry")}}</el-menu-item>
                    <el-menu-item index="4-2-1">{{$t("Internetsecurity")}}</el-menu-item>
                    <el-menu-item index="4-2-2">{{$t("Equipment")}}</el-menu-item>
                    <el-menu-item index="4-2-3">{{$t("Intelligent")}}</el-menu-item>
                  </section>

                  <el-menu-item index="4-2-4">{{$t("ZigBee")}}</el-menu-item>
                  <el-menu-item index="4-2-6">{{$t("BLE")}}</el-menu-item>
                  <el-menu-item index="4-2-7">{{$t("FG")}}</el-menu-item>
                  <el-menu-item index="4-2-8">{{$t("IoT")}}</el-menu-item>
                  <el-menu-item index="4-2-9" v-if="this.$i18n.locale == 'en'">{{$t("SmartWaste")}}</el-menu-item>

              </el-submenu>
              <el-submenu index="4-3">
                <template slot="title">{{$t("MotorControl")}}</template>
                  <el-menu-item index="4-3-1">{{$t("MotorControl")}}</el-menu-item>
                  <el-menu-item index="4-3-2">{{$t("IPM")}}</el-menu-item>
                  <el-menu-item index="4-3-3">{{$t("HighSpeed")}}</el-menu-item>
                  <el-menu-item index="4-3-4">{{$t("HighVoltage")}}</el-menu-item>
              </el-submenu>
              <el-submenu index="4-4">
                <template slot="title">{{$t("SMPS")}}</template>
                  <el-menu-item index="4-4-1">{{$t("SmartSMPS")}}</el-menu-item>
                  <el-menu-item index="4-4-2">{{$t("DigitalPower")}}</el-menu-item>
                  <el-menu-item index="4-4-3">{{$t("DigitalDimmer")}}</el-menu-item>
                  <el-menu-item index="4-4-4">{{$t("MultiFunction")}}</el-menu-item>
                  <el-menu-item index="4-4-5">{{$t("LEDDimming")}}</el-menu-item>
              </el-submenu>
            </el-submenu>
            <!-- <el-submenu index="5">
              <template slot="title">{{$t('InvestorRelations')}}</template>
              <el-menu-item :index="'5-'+ item.id" v-for="item in investList" :key="item.id" @click="invest(item)">{{$t(item.name)}}</el-menu-item>
            </el-submenu> -->
            <el-submenu index="6">
              <template slot="title">{{$t('ContactUs')}}</template>
              <el-menu-item :index="'6-'+ item.id" v-for="item in contactUs" :key="item.id" @click="contact(item)">{{$t(item.name)}}</el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
        <div class="menu" @click="drawer = true"><i class="el-icon-s-fold"></i></div>
      </div>
    </div>
    <div class="breadcrumb">
      <i class="el-icon-s-home"></i>
      <span style="cursor: pointer" @click="goIndex">{{$t('Home')}}</span>
    </div>
    <el-drawer
    ref="drawer"
      style="height: 100%"
      :visible.sync="drawer"
      direction="ttb">
          <div class="menuDialog">
           <el-menu ref="tab" active-text-color="#0055a3" :default-active="tabDefault" class="el-menu-demo" mode="vertical" @select="handleSelect">
            <el-menu-item index="1" @click="index">{{$t('Home')}}</el-menu-item>
            <el-submenu index="2">
              <template slot="title">{{$t("AboutWillas")}}</template>
              <el-menu-item :index="'2-'+ item.id" @click="aboutGo(item)" v-for="item in aboutData" :key="item.id">{{item.name}}</el-menu-item>
            </el-submenu>
            <el-menu-item index="3" @click="makerHtml">{{$t("Manufacturers")}}</el-menu-item>
           <el-submenu index="4">
              <template slot="title">{{$t("ReferenceSolutions")}}</template>
              <el-submenu index="4-1">
                  <template slot="title">汽車電子/信息娛樂</template>
                  <el-menu-item index="4-1-1">BMS系統</el-menu-item>
                  <el-menu-item index="4-1-2">車門或控制系統</el-menu-item>
                  <el-menu-item index="4-1-3">{{$t('FCFNV')}}</el-menu-item>
                  <el-menu-item index="4-1-4">{{$t('BLDC')}}</el-menu-item>
                  <el-menu-item index="4-1-5">{{$t('CMOS')}}</el-menu-item>
                  <el-menu-item index="4-1-6">{{$t('Digital')}}</el-menu-item>
              </el-submenu>
              <el-submenu index="4-2">
                <template slot="title">{{$t("Internet")}}</template>
                  <el-menu-item index="4-2-1">智能工業</el-menu-item>
                  <el-menu-item index="4-2-2">無糧網安全技術</el-menu-item>
                  <el-menu-item index="4-2-3">設備管理系統</el-menu-item>
                  <el-menu-item index="4-2-4">智慧垃圾治理系統</el-menu-item>
                  <el-menu-item index="4-2-5">ZIGBee & BLE模組(STM32WB)</el-menu-item>
                  <el-menu-item index="4-2-6">BLE模組(BLEUENGR)</el-menu-item>
                  <el-menu-item index="4-2-7">4G模組</el-menu-item>
                  <el-menu-item index="4-2-8">NB-loT模組</el-menu-item>
                  <el-menu-item index="4-2-9">藍牙斷路器</el-menu-item>
              </el-submenu>
              <el-submenu index="4-3">
                <template slot="title">電機驅動</template>
                  <el-menu-item index="4-3-1">無位置檢測滾筒洗衣機</el-menu-item>
                  <el-menu-item index="4-3-2">IPM電機驅動</el-menu-item>
                  <el-menu-item index="4-3-3">高速電機驅動</el-menu-item>
                  <el-menu-item index="4-3-4">高壓集成控制電機驅動</el-menu-item>
              </el-submenu>
              <el-submenu index="4-4">
                <template slot="title">開關電源/智能照明</template>
                  <el-menu-item index="4-4-1">智能電源電表</el-menu-item>
                  <el-menu-item index="4-4-2">數字電源</el-menu-item>
                  <el-menu-item index="4-4-3">數字可編程調光器</el-menu-item>
                  <el-menu-item index="4-4-4">多功能調光LED驅動</el-menu-item>
                  <el-menu-item index="4-4-5">帶NFC控制LED調光驅動</el-menu-item>
              </el-submenu>
            </el-submenu>
            <!-- <el-submenu index="5">
              <template slot="title">{{$t('InvestorRelations')}}</template>
              <el-menu-item :index="'5-'+ item.id" v-for="item in investList" :key="item.id" @click="invest(item)">{{item.name}}</el-menu-item>
            </el-submenu> -->
            <el-submenu index="6">
              <template slot="title">{{$t('ContactUs')}}</template>
              <el-menu-item :index="'6-'+ item.id" v-for="item in contactUs" :key="item.id" @click="contact(item)">{{item.name}}</el-menu-item>
            </el-submenu>
          </el-menu>
          </div>
    </el-drawer>
  </div>
</template>

<script>
import {setTabSelect, getTabSelect} from '../utils/auth'
import service from '../request/index'

export default {
  data() {
    return {
      drop:[
        // {name:'zhf', lang: 1,mark:'zhf'},
        {name:'zh', lang:1,mark:'zh'},
        {name:'EN', lang: 2,mark:'en'}
      ],
      defaultLang:2,
      inputShow: false,
      search: '',
      tabDefault: this.$store.state.tabSelect,
      drawer: false,
      // 关于我们tab
      aboutData:[
        {
          name:'AboutUs',
          id: 0
        },
        {
          name:'BusinessScope',
          id: 1
        },
        // {
        //   name:'Milestones',
        //   id: 2
        // },
        {
          name:'OMV',
          id: 3
        },
        // {
        //   name:'BusinessStructure',
        //   id: 4
        // },
        // {
        //   name:'BDSM',
        //   id: 5
        // },
        // {
        //   name:'Careers',
        //   id: 6
        // }
      ],
      // 联系我们tab
      contactUs:[
        {
          name:'HLC',
          id: 0
        },
        {
          name:'SalesOffices',
          id: 1
        },
        {
          name:'EmailMe',
          id: 2
        }
      ],
      // 投资者tab
      // investList:[
      //   {
      //     name:'SEHK',
      //     id: 0
      //   },
      //   {
      //     name:'SGX',
      //     id: 1
      //   },
      //   {
      //     name:'EmailMe',
      //     id: 2
      //   }
      // ],

      onlyKey: 1,
    }
  },
  watch:{
    '$store.state.tabSelect'(c) {
      this.tabDefault = c
      this.$refs.tab.$forceUpdate()
    }
  },
  created() {
    let lang = window.localStorage.getItem('lang');
    console.log(lang)
    if (lang){
      this.defaultLang = lang;
      this.$i18n.locale =  this.drop[lang-1].mark;

    }
  },
  mounted() {
    this.tabDefault =this.$store.state.tabSelect
    this.onlyKey = + new Date()
    console.log(this.tabDefault);
    console.log(this.onlyKey);
  },
  methods: {
    checkoutLang(command_id){
      console.log(command_id);
      this.defaultLang = this.drop[command_id-1].lang;
      this.$i18n.locale =  this.drop[command_id-1].mark;
      window.localStorage.setItem('lang',command_id);
      window.localStorage.setItem('requestLang',this.drop[command_id-1].mark);
      console.log(this.$route.path);
      
      if(this.$route.path == '/newsMore') {
        this.$router.push('/SEHK')
      }
      location.reload();

    },
    // 搜索按钮
    onSearch() {
      if(!this.inputShow) {
        this.inputShow = true
      }else {
        console.log('搜索');
      }
    },
    // 首页
    index() {
      this.$router.push('/')
    },
    goIndex() {
      this.$router.push('/')
    },
    // 关于tab跳转
    aboutGo(item) {
      console.log(item);
      switch(item.id) {
        case 0:
          this.$router.push('/about');
          break;
        case 1:
          this.$router.push('/business');
          break;
        case 2:
          this.$router.push('/progress');
          break;
        case 3:
          this.$router.push('/mission');
          break;
        case 4:
          this.$router.push('/framework');
          break;
        case 5:
          this.$router.push('/sysman');
          break;
        case 6:
        this.$router.push('/recruitment');
          break;
        default:
          this.$router.push('/');
      }
    },
    // 制造商跳转
    makerHtml() {
      this.$router.push('/maker')
    },
    // 关于我们跳转
    contact(item) {
      switch(item.id) {
        case 0:
          this.$router.push('/headquarters');
          break;
        case 1:
          this.$router.push('/office');
          break;
        case 2:
          this.$router.push('/email');
          break;
        default:
          this.$router.push('/');
      }
    },
    // 投资者跳转
    // invest(item) {
    //   switch(item.id) {
    //     case 0:
    //       this.$router.push('/SEHK');
    //       break;
    //     case 1:
    //       this.$router.push('/SGX');
    //       break;
    //     case 2:
    //       this.$router.push('/email');
    //       break;
    //     default:
    //       this.$router.push('/');
    //   }
    // },
    handleSelect(index, indexPath) {
      // setTabSelect(indexPath[0])
      this.$store.commit('setTabSelet', indexPath[0])
      console.log(index, indexPath);
      if(indexPath[0] !== '4') return
      switch(indexPath[2]) {
        case '4-1-1':
          this.$router.push('/BMS')
          break;
        case '4-1-2':
          this.$router.push('/car')
          break;
        case '4-1-3':
          this.$router.push('/newEnergy')
          break;
        case '4-1-4':
          this.$router.push('/waterPump')
          break;
        case '4-1-5':
          this.$router.push('/CMOS')
          break;
        case '4-1-6':
          this.$router.push('/radio')
          break;
        case '4-2-1':
          this.$router.push('/AI')
          break;
        case '4-2-2':
          this.$router.push('/inter')
          break;
        case '4-2-3':
          this.$router.push('/device')
          break;
        case '4-2-4':
          this.$router.push('/zhihui')
          break;
        case '4-2-5':
          this.$router.push('/ZIGbEE')
          break;
        case '4-2-6':
          this.$router.push('/BLE')
          break;
        case '4-2-7':
          this.$router.push('/4G')
          break;
        case '4-2-8':
          this.$router.push('/NB')
          break;
        case '4-2-9':
          this.$router.push('/bluetooth')
          break;
        case '4-3-1':
          this.$router.push('/washer')
          break;
        case '4-3-2':
          this.$router.push('/IPM')
          break;
        case '4-3-3':
          this.$router.push('/lowTension')
          break;
        case '4-3-4':
          this.$router.push('/highHanded')
          break;
        case '4-4-1':
          this.$router.push('/powerSupply')
          break;
        case '4-4-2':
          this.$router.push('/numberSupply')
          break;
        case '4-4-3':
          this.$router.push('/programming')
          break;
        case '4-4-4':
          this.$router.push('/LED')
          break;
        case '4-4-5':
          this.$router.push('/NFC')
          break;
        default:
          this.$router.push('/')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1200px) {
    .logRight {
      display: none;
    }
    .menu {
      display: block !important;
      cursor: pointer;
    }
}
  ::v-deep.parent {
    .el-drawer {
      height: 100% !important;
    }
    .menuDialog {
      width: 50%;
      margin: 50px auto;
    }
    .el-menu {
      border: unset;
    }
  }

  .navContainer {
    width: 100%;
    height: 40px;
    background-color: #4E575C;
  }
  .banner {
    max-width: 1200px;
    margin: 0 auto;
    height: 40px;
    clear: both;
  }
  .left {
    float: left;
    color: #fff;
    ul {
      display: flex;
      // float: left;
      // display: inline-block;
      li {
        width: 30px;
        padding-top: 4px;
        cursor: pointer;
      }
      span {
        width: 30px;
        height: 30px;
        .st0 {
          fill: #fff;
        }
      }
    }
  }
  .right {
    float: right;
    color: #fff;
    line-height: 40px;
    .el-icon-search {
      font-weight: bold;
      font-size: 20px;
      margin-left: 19px;
    }
    ::v-deep.drop {
      cursor: pointer;
      margin-left: 17px;
      .el-dropdown {
        color: #fff;
      }
    }
    input {
      width: 200px;
      height: 25px;
      border-radius: 4px;
      border: unset;
      outline: unset;
      padding: 0 5px;
    }
  }
  .el-dropdown-menu {
    background-color: #4E575C;
    color: #fff;
    li {
      color: #fff;
    }
    .el-dropdown-menu__item:not(.is-disabled):hover {
      background-color: unset;
      color: unset;
    }
  }
  .log {
    height: 80px;
    max-width: 1200px;
    margin: 0 auto;
    .logFlex {
      display: flex;
      height: 100%;
      width: 100%;
    }
    .logLeft {
      flex: 4;
      height: 50px;
      margin-top: 4px;
      display: flex;
      .img {
        width: 90px;
        height: 50px;
      }
      .img2 {
        width: 110px;
        height: 65px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    ::v-deep.logRight {
      flex: 6;
      float: right;
      .el-menu.el-menu--horizontal {
        border: unset;
        float: right;
        padding-top: 7px;
      }
      .el-menu-item {
        color: #4E575C;
        font-weight: bold;
        border: unset;
      }
      .el-submenu__title {
        font-weight: bold;
        color: #4E575C ;
      }
      .el-menu-item:hover {
        color: #0055a3;
      }
      .el-submenu__title:hover {
        color: #0055a3 !important;
      }
      .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
        border-bottom: unset;
      }
    }
    .menu {
      display: none;
      flex: 6;
      float: right;
      text-align: end;
      line-height: 80px;
      i {
        font-size: 34px;
      }
    }
  }
  .breadcrumb {
    height: 50px;
    max-width: 1200px;
    background-color: #5A6266;
    margin: 0 auto;
    line-height: 50px;
    color: #fff;
    font-size: 12px;
    padding: 0 12px;
    box-sizing: border-box;
    .el-icon-s-home {
      font-size: 16px;
      color: #fff;
    }
    span {
      padding: 0 7px;
    }
  }
</style>
