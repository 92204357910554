import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/index'
  },
  // 首页
  {
    path: '/index',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/index/index.vue')
  },
  // 关于我们
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/index.vue')
  },
  // 业务范围
  {
    path: '/business',
    name: 'business',
    component: () => import('../views/about/business.vue')
  },
  // 发展里程
  {
    path: '/progress',
    name: 'progress',
    component: () => import('../views/about/progress.vue')
  },
  // 使命、抱负、信念
  {
    path: '/mission',
    name: 'mission',
    component: () => import('../views/about/mission.vue')
  },
  // 业务架构
  {
    path: '/framework',
    name: 'framework',
    component: () => import('../views/about/framework.vue')
  },
  // 董事会及高级管理员
  {
    path: '/sysman',
    name: 'sysman',
    component: () => import('../views/about/sysman.vue')
  },
  // 招聘
  {
    path: '/recruitment',
    name: 'recruitment',
    component: () => import('../views/about/recruitment.vue')
  },
  // 制造商
  {
    path: '/maker',
    name: 'maker',
    component: () => import('../views/maker/index.vue')
  },
  // 集团总部
  {
    path: '/headquarters',
    name: 'headquarters',
    component: () => import('../views/contactUs/headquarters.vue')
  },
  // 办事处
  {
    path: '/office',
    name: 'office',
    component: () => import('../views/contactUs/office.vue')
  },
  // SEHK投資者
  {
    path: '/SEHK',
    name: 'SEHK',
    component: () => import('../views/investment/SEHK.vue')
  },
  // SGX投資者
  {
    path: '/SGX',
    name: 'SGX',
    component: () => import('../views/investment/SGX.vue')
  },
  // 電郵提醒
  {
    path: '/email',
    name: 'email',
    component: () => import('../views/investment/email.vue')
  },
  // BMS
  {
    path: '/BMS',
    name: 'BMS',
    component: () => import('../views/scheme/BMS.vue')
  },
  // 車門控制系統
  {
    path: '/car',
    name: 'car',
    component: () => import('../views/scheme/car.vue')
  },
  // 新能源
  {
    path: '/newEnergy',
    name: 'newEnergy',
    component: () => import('../views/scheme/newEnergy.vue')
  },
  // 五點刷水泵
  {
    path: '/waterPump',
    name: 'waterPump',
    component: () => import('../views/scheme/waterPump.vue')
  },
  // CMOS
  {
    path: '/CMOS',
    name: 'CMOS',
    component: () => import('../views/scheme/CMOS.vue')
  },
  // 數字收音機
  {
    path: '/radio',
    name: 'radio',
    component: () => import('../views/scheme/radio.vue')
  },
  // 智能工業
  {
    path: '/AI',
    name: 'AI',
    component: () => import('../views/scheme/AI.vue')
  },
  // 無糧網技術
  {
    path: '/inter',
    name: 'inter',
    component: () => import('../views/scheme/inter.vue')
  },
  // 設備
  {
    path: '/device',
    name: 'device',
    component: () => import('../views/scheme/device.vue')
  },
  // 智慧
  {
    path: '/zhihui',
    name: 'zhihui',
    component: () => import('../views/scheme/zhihui.vue')
  },
  // zigbee
  {
    path: '/ZIGbEE',
    name: 'ZIGbEE',
    component: () => import('../views/scheme/ZIGbEE.vue')
  },
  // BLE
  {
    path: '/BLE',
    name: 'BLE',
    component: () => import('../views/scheme/BLE.vue')
  },
  // 4G
  {
    path: '/4G',
    name: '4G',
    component: () => import('../views/scheme/4G.vue')
  },
  // NB
  {
    path: '/NB',
    name: 'NB',
    component: () => import('../views/scheme/NB.vue')
  },
  // bluetooth
  {
    path: '/bluetooth',
    name: 'bluetooth',
    component: () => import('../views/scheme/bluetooth.vue')
  },
  // washer
  {
    path: '/washer',
    name: 'washer',
    component: () => import('../views/scheme/washer.vue')
  },
  // IPM
  {
    path: '/IPM',
    name: 'IPM',
    component: () => import('../views/scheme/IPM.vue')
  },
  // lowTension
  {
    path: '/lowTension',
    name: 'lowTension',
    component: () => import('../views/scheme/lowTension.vue')
  },
  // highHanded
  {
    path: '/highHanded',
    name: 'highHanded',
    component: () => import('../views/scheme/highHanded.vue')
  },
  // powerSupply
  {
    path: '/powerSupply',
    name: 'powerSupply',
    component: () => import('../views/scheme/powerSupply.vue')
  },
  // 數字電源
  {
    path: '/numberSupply',
    name: 'numberSupply',
    component: () => import('../views/scheme/numberSupply.vue')
  },
  // 可編程
  {
    path: '/programming',
    name: 'programming',
    component: () => import('../views/scheme/programming.vue')
  },
  // LED
  {
    path: '/LED',
    name: 'LED',
    component: () => import('../views/scheme/LED.vue')
  },
  // NFC
  {
    path: '/NFC',
    name: 'NFC',
    component: () => import('../views/scheme/NFC.vue')
  },
  // 其他解决方案
  {
    path: '/ohter',
    name: 'ohter',
    component: () => import('../views/rests/resolve.vue')
  },
  // adas
  {
    path: '/adas',
    name: 'adas',
    component: () => import('../views/rests/adas.vue')
  },
  // tiaojian
  {
    path: '/tiaojian',
    name: 'tiaojian',
    component: () => import('../views/rests/tiaojian.vue')
  },
  // 隐私政策
  {
    path: '/xiaoshou',
    name: 'xiaoshou',
    component: () => import('../views/rests/xiaoshou.vue')
  },
  // 使用条款
  {
    path: '/useJS',
    name: 'useJS',
    component: () => import('../views/rests/use.vue')
  },
  // 最新咨询
  {
    path: '/newMore',
    name: 'newMore',
    component: () => import('../views/rests/newMore.vue')
  },
  // 最新咨询2
  {
    path: '/newMoreTwo',
    name: 'newMoreTwo',
    component: () => import('../views/rests/newMoreTwo.vue')
  },
  // 公司简介书
  {
    path: '/jianjie',
    name: 'jianjie',
    component: () => import('../views/rests/jianjie.vue')
  },
  // 简介详情
  {
    path: '/jianjieDetail',
    name: 'jianjieDetail',
    component: () => import('../views/rests/jianjieDetail.vue')
  },
  // 新闻详情
  {
    path: '/newsMore',
    name: 'newsMore',
    component: () => import('../views/investment/newsMore.vue')
  },
  // 参考方案详情
  {
    path: '/recruitmentDetail',
    name: 'recruitmentDetail',
    component: () => import('../views/about/recruitmentDetail.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savePosition) {
    // return 期望滚动到哪个的位置
    return {
      y: 0
    }
  }
})

export default router
