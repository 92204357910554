<template>
  <div class="swiperBox">
    <div class="banner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in dataImg" :key="index">
            <img width="100%" height="100%" :src="item.url" alt="">
          </div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'swiper/css/swiper.css'  //引入swiper样式
  import Swiper from 'swiper';
export default {
  data() {
    return {
      dataImg:[{
        url: require('../assets/imags/banner1.webp')
      },
      {
        url: require('../assets/imags/22040024-Willas-Array-Murata-Battery-CT04120-e.webp')
      },
      {
        url: require('../assets/imags/22040062-Willas-Array-STM32WB-eDM-May-Vol.2_We.webp')
      },{
        url: require('../assets/imags/22040063-Willas-Array-Sanken-Automotive-eDM-Ma.webp')
      }
      ]
    }
  },
  mounted() {
      var mySwiper = new Swiper('.swiper-container', {
        observer:true,//修改swiper⾃⼰或⼦元素时，⾃动初始化swiper
        observeParents:true,//修改swiper的⽗元素时，⾃动初始化swiper
        loop:true,
        loopAdditionalSlides: 2,
        speed:1300,
        autoplay: {
        delay: 5000,
        disableOnInteraction: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      })
  },
  components: {
  },
  methods:{}
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1200px) {
    .swiperBox .banner .swiper-button-next {
      right: 0 !important;
    }
    .swiperBox .banner .swiper-button-prev {
      left: 0 !important;
    }
}

  .swiperBox {
    width: 100%;
    height: 450px;
    ::v-deep.banner {
      max-width: 1200px;
      height: 100%;
      margin: 0 auto;
      .swiper-wrapper{
        img{
          width: 100%;
          height: 100%;
          background-size: cover;
        }


      }
      .swiper-slide {
        width: 100%;
        height: 100%;
        position: relative;
      }
      .text {
        position: absolute;
        width: 590px;
        height: 150px;
        top: 38%;
        left: 10%;
        color: #000;
        font-size: 36px;
        font-weight: 600;
        text-align: center;
        padding: 20px;
        background-color: rgba(255,255,255,.6);
      }
      .swiper-container {
        width: 100%;
        height: 100%;
        overflow: unset;
      }
      .swiper-button-next {
        width: 46px;
        height: 75px;
        background-color: #0055a3;
        right: -46px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      .swiper-button-prev {
        width: 46px;
        height: 75px;
        background-color: #0055a3;
        left: -46px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
        color: #fff;
      }
      .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
        color: #fff;
      }
    }
  }
</style>
