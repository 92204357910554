import enLocale from 'element-ui/lib/locale/lang/en'
export default {
  EN: "EN",
  zhf: "繁體中文",
  zh: "简体中文",
  Home:"Home",
  AboutWillas:"About China Electronics",
  AboutUs:"About Us",
  BusinessScope:"Business Scope",
  OMV:"Ambition、Mission、Belief",
  BusinessStructure:"Business Architecture",
  BDSM:"Board of Directors and Senior Management",
  Careers:"Careers",
  Manufacturers:"Manufacturer's Brand",
  ReferenceSolutions:"Reference scheme",
  SchemeQuery:"Scheme query",
  AutomotiveInfotainment:"Automotive/Infotainment",
  BMS:"BMS",
  DZC:"Door Zone Control",
  FCFNV:"FOC Cooling Fan for New EV",
  BLDC:"BLDC Water Pump",
  CMOS:"CMOS Sensor Camera",
  Digital:"Digital Car Tuner + DSP + AMP",
  Internet:"Internet of Things",
  ZigBee:"ZigBee / BLE Module (STM32WB)",
  BLE:"BLE Module (BLUENGR)",
  FG:"4G Module",
  IoT:"NB-IoT Module",
  Bluetooth:"Bluetooth Circuit Breaker",
  Intelligent:"Intelligent waste treatment system",
  Equipment:"Equipment management system",
  Intelligentindustry:"Smart Industry",
  SmartWaste:"Smart Waste Management",
  ename:'English name',
  yuangongxinxi:'Employee information',
  closebtn:'close',




  MotorControl:"Motor Control",
  Sensorless:"Sensorless Front Load Washing Machine",
  IPM:"IPM Motor Drive",
  HighSpeed:"High-Speed  Low-Voltage FOC Motor Driver",
  HighVoltage:"High Voltage Integrated Control Motor Driver",
  SMPS:"SMPS / Smart LED",
  SmartSMPS:"Smart Meter SMPS",
  DigitalPower:"Digital Power",
  DigitalDimmer:"Digital Programmable Dimmer",
  MultiFunction:"Multifunctional Dimmable LED Driver",
  LEDDimming:"LED dimming driver with NFC control",




  Internetsecurity:"Internet of things security technology",
  InvestorRelations:"Investor relations",
  ContactUs:"Contact Us",
  IoTDevice:"product application",
  IoTDevice2: 'Microchip products',
  ComponentMarket:"Strive Stronger in the Component Market",
  NEW:"Product Highlights",
  TypeC:"Nanogeneration is coming",
  Dexerials:"What is an ideal diode? ?",
  Readmore:"Read more",
  Publication:"Focus on detection",
  Joinus:"Join us to convert tomorrow's innovation into today's opportunity.",
  PublicationD:"Join us to convert tomorrow's innovation into today's opportunity.",
  CorporateNews:"Industry news",
  Overseas:"The semiconductor market is expected to grow by 5.1 in 2020",
  Regulatory:"The global semiconductor assembly market will grow",
  Announcement:"The pure wafer foundry market has seen the strongest growth since 2014",
  More:"More",
  yf:"June 14, 2022",
  AboutTitle:"company profile",
  AboutTitle_C:"China Electronics Industry Group Co., LTD. (referred to as CEIC) focus on distributing electronic components for customers, Serving customers worldwide in the  consumer, automotive, industrial, medical devices, computing, telecommunications & transportation, aerospace industries.",
  AboutTitle_2:"Developing Insightful Design and Solution",
  AboutTitle_C2:"In order to meet the needs of customers in different regions around the world, China Electronics Industry Group Co., Ltd. has an all-round procurement and sales team our customers can get the most accurate information in time. Logistics centers are specially set up in Hong Kong and Shenzhen. All goods are strictly inspected before shipment to ensure the original and new goods. The door-to-door express service is adopted to ensure that emergency goods can be delivered to customers within 3 days to meet their urgent needs",
  Connect:"Connect With Our Solution Expert",
  Contact:"Contact Us",
  OnWeChat:"Follow Us on WeChat",
  Headquarters:"Headquarters",
  Address:"HK Office",
  Addr_detial:"Room 7-11, Floor 7M, Building No.4, 3th Area, Hanwei International Plaza, No.186, South 4th Ring West Road, Fengtai District, Beijing City, China.",
  Email:"Email",
  wuliuCenter: "Logistics center",
  wuliuAddress: "Room 601, 6 / F, Weida Centre, 8 on Ping Street, Siu Lek Yuen, Shatin, New Territories, Hong Kong",
  Phone:"Phone",
  Fax:"Fax",
  Sitemap:"Sitemap",
  Manufacturerss:"Manufacturers",
  SalesOffices:"Sales Offices",
  TermsCondition:"Terms and Condition",
  PrivacyPolicy:"Privacy Policy",
  TermsUse:"Terms of Use",
  HLC:"Headquarters & Logistics Centre",
  SEHK:"SEHK Investors",
  SGX:"SGX Investors",
  EmailMe:"Email Me",



  Wide:"Wide range of products",
  WideC:"The Group offers a massive range of product to facilitate customers to deal with nowadays dynamic business environment. That includes: Automotive, Infotainment, Internet of Things, Motor Control, SMPS and Smart LED etc. The product portfolio provides good base for high value design and solution for customer to be agile enough to meet with their business needs.",
  Reference:"Reference Solutions",
  ReferenceC:"As an industry explorer, in addition to paying close attention to the latest market trends and providing high-end electronic components for different customers, the Group's high-quality engineering team has been working tirelessly to develop new design concepts, improve product application technology, and strive to achieve Complete the research and development in a short period of time and meet the technical requirements of different customers, shorten the customer's research and development cycle, greatly speed up the time to launch the market, and strengthen the competitiveness of customers in the market.",
  ConvertTomorrow:'Innovation potential to expand the future · Looking forward to China to seize the opportunity',
  OurMission:'Our Mission',
  AsiaVision:'The Asia Vision',
  AsiaVisionC:'China Electronics Industry Group Co., Ltd. has a solid foundation in the Greater China region and the advantages of its sales network. With its huge scale and professional services, it has explored cooperation opportunities with all parts of Asia.',
  EfficiencyAdvancement:'Efficiency Advancement',
  EfficiencyAdvancementC:'Although the internal systems and processes are mature, we continue to pursue better competitiveness, improve the efficiency of various departments, optimize human resources and resources, complete research and development in a short period of time and meet the technical requirements of different customers, consolidate and enhance the groups market position to expand sales and marketing business.',
  ServiceOptimization:'Service Optimization',
  ServiceOptimizationC:'Actively strive for innovation and service optimization, enhance company value through a unified team, and become the most trusted and cooperative partner for more suppliers and customers.',
  Insightful:'Insightful Ideas',
  InsightfulC:'The strong technical department is always standing with customers. From the customer point of view, according to their cost and functional requirements, we are committed to integrating new development concepts into high value-added market needs and budgets, improving system design energy efficiency, and promoting customers. Cost-effective design options.',
  OurValues:'Our Values',
  ValueAddedC:'We believe in the importance of superior service and strive to provide value-added services to our customers. In order to further enhance the operating efficiency of the Group, the Group uses the enterprise management integration system SAP, and cooperates with the IT department to continuously improve it to enhance the operation of supply chain management, inventory, sales and risk management; coupled with the cooperation of professional logistics and logistics personnel, To ensure that we can continue to provide customers with fast and punctual delivery services in todays highly competitive environment.',

  Trust:'Trust',
  TrustC:'Committed to building trust and commitment with suppliers and customers, collecting a richer supply of cutting-edge products, exploring newer and more flexible technical support solutions, and jointly facing various challenges in the market.',
  Human:'Human Orientation',
  HumanC:'The Group has high-quality and experienced employees, and not only cherishes these invaluable talents, but also encourages front-line employees to be honest, open and innovative, and to grow and improve rapidly in the process of continuous learning.',
  Opportunities:'Opportunities',
  OpportunitiesC:'We regard employees and customers as our indispensable and important partners both internally and externally. In addition to proactively anticipating and meeting their changing needs, we also provide them with a full range of information services, giving them advantages, flexibility and opportunities for success.',
  Integrity:'Integrity',
  IntegrityC:'Adhere to the commitment to customers, partners and employees, and operate with an open and loyal attitude. While striving for innovation, it also attaches great importance to the guarantee of excellent quality. Whether it is a product or a solution, it provides customers with high-quality products that are higher than expected, and establishes a good reputation for quality and service.',
  Director:'Non-executive Director',
  Leung:'Leung Chun Wah',
  Chairman:'Chairman and Non-executive Director',
  Executive:'Executive Directors',
  HonKarChun:'Hon Kar Chun',
  LeungHonShing:'Leung Hon Shing',
  LeungChiHangDaniel:'Leung Chi Hang Daniel',
  HonKarChunZ:'Managing Director and Executive Director',
  LeungHonShingZ:'Executive Director, Chief Financial Officer and Company Secretary',
  LeungChiHangDanielZ:'Executive Director',
  LogisticsCentre:'Logistics Centre',
  LogisticsCentreAddr:'Room 601, 6 / F, Weida center, 8 on Ping Street, Siu Lek Yuen, Shatin, New Territories, Hong Kong ',


  carInfo:"Automotive Electronics / Infotainment",
  SmartIndustry:"Smart Industry",
  switch:"Switching Power Supply/Smart Lighting",
  introduce:"introduce",
  introducec:"BMS is an important link connecting new energy vehicle battery packs, vehicle control and motors, and is responsible for the overall management of new energy vehicle battery packs. It mainly includes three core functions, namely battery pack data collection, battery pack status estimation, and cell single Body consistency maintenance.",

  ProgramFeatures:"Program Features",
  photo:"Block Diagram / Evaluation Board / Photos",
  moreReferenceSolution:"more solutions",
  ReferenceSchemeCon:"We are committed to incorporating new development concepts into cost-effective reference designs to drive more cost-effective solutions for our customers.",
  ContactUsCon:"If you are interested in this solution, please contact us for professional design advice.",
  Ltd:"China Electronics Industry Group Co., Ltd.",
  grid:"sales network",
  btnLianxi:"Please click here to contact our sales office",
  zongLtd:"head office",
  disn:"e-mail",
  jituanc:"Group enquiry",
  xiaosouc:"Sales Inquiry",
  submit:"submit",
  yingyin:"Scope of application",
  laji:"Smart waste management system",
  mozu:"BLE module (BLUENGR)",
  mozufour:"4G module",
  mozunb:"NB-IoT module",
  bluur:"bluetooth circuit breaker",
  gunji:"Front-loading washing machine without position detection",
  ipmj:"IPM motor drive",
  gsdianji:"High-speed low-voltage vector motor drive",
  gaoqudong:"High Voltage Integrated Control Motor Drive",
  dianboa:"Smart Meter Power",
  shuzidian:"digital power",
  shuzikeitao:"Digital Programmable Dimmer",
  ledduo:"Multifunctional Dimmable LED Driver",
  nfcdu:"LED Dimming Driver with NFC Control",

  jituanzon:"Group headquarters",
  xg:"Hongkong",
  jituannew:"group News",
  chezai:"Vehicle Electronics",
  xiaofeichan:"Consumer electronics products",
  tongxuns:"communication network",
  dongli:"Powertrains and Hybrid Systems",
  dongli2:"Chassis and Safety",
  dongli3:"body electronics",
  dongli4:"In-vehicle infotainment system",
  dongli5:"Dashboard",
  dongli6:"Advanced Driver Assistance",
  dongli7:"Internet of Vehicles",



  kechuan:"Wearable device",
kechuan1:"life entertainment",
kechuan2:"television",
kechuan3:"Game video and audio",
kechuan4:"household equipment",
kechuan5:"Broadcast set-top box",
kechuan6:"household equipment",

chuanshus:"Optical Access/Transport Network",
chuanshus1:"Wireless Router",
chuanshus2:"TV transmission",
chuanshus3:"Wireless LAN",
chuanshus4:"Optical communication",
chuanshus5:"5G application",
ppp:"Microchip Technology Inc. is a leading supplier of microcontrollers and analog semiconductors, providing low-risk product development, lower total system cost and faster time-to-market for thousands of different customer applications worldwide. Headquartered in Chandler, Arizona, Microchip provides outstanding technical support and reliable delivery and quality.",
adipp:"Analog Devices' analog, mixed-signal, and digital signal processing (DSP) integrated circuits (ICs) convert, condition, and process real-world phenomena such as light, sound, temperature, motion, and pressure into electrical signals for use in a variety of electronic devices. ADI is synonymous with high performance among electronics manufacturers, and we work with our customers to define the best quality of user experience.",
banpp:"STMicroelectronics is a global independent semiconductor company with a leadership position in developing and delivering semiconductor solutions for microelectronics applications. An unparalleled combination of silicon and systems expertise, manufacturing strength, intellectual property (IP) portfolio and strategic partners puts the company at the forefront of system-on-chip (SoC) technology, and its products play a key role in enabling today's convergence trends.",
depp:"Texas Instruments (TI) is a global semiconductor design and manufacturing company that develops analog ICs and embedded processors. By hiring the world's brightest minds, TI creates innovations that shape the future of technology. Today, TI is helping more than 100,000 customers change the future.",
avspp:"AVX Corporation is a recognized leader in the global passive electronic components and interconnect products industry. AVX Corporation takes up the challenge every year to help design engineers leverage AVX's cutting-edge research, design expertise and materials technology to create superior products for customers that improve performance and reduce total cost. Global manufacturing capabilities in 17 countries on four continents enable AVX to meet customer needs on a global scale.",
gonsijianjieshu:"Company Profile",
zhuanzhujian:"focus on detection",
waibujian:"External visual inspection",
wibupp:"Visual inspection is to check the number of parts, inner packaging, dry and outer packaging, vacuum packaging for integrity, dappled heat press marking for clear and high consistency, manufacturer's logo location and print (including date code and country/region) ) according to the manufacturer's standard.",
xinpian:"Chip detection",
xinpianpp:"Chip inspection requires components to be of the same shape/color, with the same finish on the front and back of the component, and no scratches. Verify secondary coating, pin status, polished marks, bumps on marks. The positioning holes are accurate and clear, and the Pin/ball color and shape are normal (no oxidation, deformation, burrs, damage, contamination, etc.). Outline dimensions, pin locations, pin spacing, and pin lengths are consistent with the package outline.",
bufenhogn:"Sub-functional testing and cooperation with third-party testing agencies",
bufenhognpp:"3rd Party Testing Centers Decap Testing X-ray Electrical Testing Functional Testing",
zhiliang:"Quality Control",
zhiliangpp:"China Electronics believes that regular supply channels are the foundation, and we always insist on testing each batch of products entering and leaving the warehouse. All products must be tested in accordance with the company's inbound and outbound procedures. At the same time, we have authoritative third-party inspection agencies as technical support to help us complete the sample inspection of outbound goods.",

honnrhnzz:"function test",
honnrhnzz2:"Verify passive components to ensure there are no openings or defects; ensure component performance meets manufacturer's specifications (resistor/capacitor/inductor).",
baozhuangwu:"Packaging and Logistics",
baozhuangwu3:"The final step in the testing service is packaging and delivery, we take it as seriously as any other testing item, all products must be shipped in ESD standard packaging, free of defects and/or modifications. We recognize the importance of timely and safe delivery of components to our customers. We provide complete packaging and shipping services to assist you in delivering your shipment to your designated destination.",
wome:"We provide electronic components and value services to the global industry",

err:"E-9963 High Precision AFE Provides Accurate Cell Voltage Acquisition",
err2:"E-SPC58 high-performance MCU provides powerful computing power",
yewuxie:"business collaboration",
yewuxie2:"Coordinate services such as vehicle management, personnel management, facility management, task processing, event issues, maps, and real-time communication, and connect businesses and various departments to form a rapid mechanism for collaborative management.",


wuchu:"Ubiquitous nanogenerators",
wuchu2:"Its appearance completely broke the limit of people's understanding of the size of 'generator'. Nanogenerators can realize the collection and utilization of extremely small amounts of mechanical energy in the environment. For example, the flow of air or water, the rotation of the engine, the operation of the machine, the stretching of the muscles when a person walks, the foot on the ground, and even the noise of various frequencies caused by breathing, the heartbeat or blood flow in the human body. A slight change in pressure somewhere in the body can drive a nanogenerator to generate electricity. Therefore, the nanogenerator theory provides an ideal power solution for the current realization of the Internet of Things, sensor networks and big data.",
wuchu3:"At present, nanogenerators can be divided into three categories: the first category is piezoelectric nanogenerators; the second category is triboelectric nanogenerators; the third category is pyroelectric nanogenerators. Generally used in biomedicine, military, wireless communication, wireless sensing and other fields.",
wuchu4:"In the current era of rapid development of stretchable and wearable electronic devices, the research on flexible mechanical energy harvesting devices is of great value and significance. In recent years, the use of flexible materials to replace commercial polymer films and metal sheets to assemble flexible triboelectric nanogenerators has become a bright spot. In recent years, there have been many research achievements on flexible triboelectric nanogenerators.",
wuch2:"Accurately testing tiny signals is challenging",
wuch3:"Due to the technical characteristics of nano-power generation, it is necessary to test the electrical energy generated by the mechanical energy per unit area during the research process. The voltage, tiny current and power signals generated by the test are basically a few volts or even tens of volts. The current is generally at the level of uA or even nA, and the power is at the level of mW or even uW. It is difficult to accurately test small current and power signals, which requires very high precision and stability of the test instrument. Tektronix Keithley specializes in the measurement of small electrical signals. Many Nobel Prize winners in physics throughout history have used and trusted Keithley test equipment. Keithley's products remain the industry's first choice in nanopower research, especially for small signal testing.",
wuch4:"The theoretical limit of measurement sensitivity depends on the noise generated by the resistors in the circuit. Voltage noise is proportional to the square root of the product of resistance, bandwidth, and absolute temperature. As can be seen from the figure, the source resistance limits the theoretical sensitivity of the voltage measurement, that is, when a 1uV signal with a source resistance of 1Ω can be accurately measured, if the source resistance of the signal becomes 1TΩ, the measurement will become impossible. Because when the source resistance is 1MΩ, the measured value of 1uV is already close to the theoretical limit. At this time, it is impossible to perform this type of measurement with an ordinary digital multimeter. Choosing the right instrument is a prerequisite to ensure accurate testing of small signals.",
wuch5:"Small current signal test",
wuch6:"Method: The nano-power generation technology of insulating materials is adopted. Generally, the source resistance is at the GΩ level and the test current is at the pA level. Therefore, the industry adopts the electrometer 6514 + Stanford SR570 (low noise current preamplifier) + special acquisition software to generate the current data set.",

whys:"What is an ideal diode? ?",
whys2:"Diodes are also called crystal diodes, commonly known as diodes. In addition, there are also the original vacuum pump electronic diodes; this is an electronic component that can conduct current unilaterally. There is a PN junction and two terminals inside the semiconductor material diode. Electronic components of this type have unidirectional conductivity depending on the direction of the applied operating voltage.",
whys3:"Generally speaking, crystalline diodes are pn junctions made by firing p-type semiconductor material and n-type semiconductor material. Space charge layers are created on both sides of the page to create an electrostatic field. When the additional operating voltage is zero, due to the difference in carrier concentration on both sides of the pn junction, the amount of out-diffusion current is the same as the amount of drift current caused by the construction of an electrostatic field, and it is in an electrical equilibrium state, which are all normalized. diode characteristics.",
whys4:"Diode Efficiency",
whys5:"Diodes are the first of the most common electronic components. Its main feature is unidirectional conduction, that is, a certain amount of current can only pass through one direction of the diode. The function of the diode is to rectify the circuit and detect the power supply circuit. Voltage regulator circuit, various demodulation power supply circuits.", 
whys6:"Diode Principle", 
whys7:"Crystal diodes are pn junctions made of p-type semiconductor material and n-type semiconductor material. A space charge layer is created on both sides of the page and an electrostatic field is established. When no additional operating voltage is found, the amount of out-diffusion current caused by different carrier concentrations on both sides of the pn junction is the same as the amount of drift current caused by the establishment of an electrostatic field, which is in an electrical equilibrium state. When there is a forward working voltage reference point outside, the mutual inhibition of the external electrostatic field and the established electrostatic field increases the amount of external diffusion current of carriers, thereby generating the amount of forward current. When there is an external working voltage reference point in the opposite direction, the external electrostatic field and the structural electrostatic field are effectively strengthened, and the reverse saturation current I0 independent of the working voltage value of the reference point in the opposite direction is generated within the working voltage range in the opposite direction. When the additional operating voltage in the opposite direction is high to a necessary level, the electric field strength in the space charge layer of the pn junction exceeds the critical point, resulting in the growth of carriers throughout the process, resulting in cavitation in many electronic devices, resulting in a very large The amount of thermal breakdown current in which the scale value is reversed is called the thermal breakdown condition of the diode. The reverse thermal breakdown of pn junction can be divided into Zener thermal breakdown and avalanche breakdown.", 
whys8:"Diode basic parameters", 
whys9:"It refers to the larger forward average current allowed when the diode works continuously for a long time, and its value is related to the total area of the PN junction and the heat dissipation standard of the external heat pipe. Due to the amount of current, the mold heats up according to the time of the water pipe, and the temperature rises. When the temperature exceeds the allowable lower limit (the silicon tube is up to 141, and the germanium tube is up to 90), the mold will be overheated and damaged. Therefore, under the requirements of heat pipe cooling standards, the diode application does not necessarily exceed the larger rectified current of the diode. For example, a common IN4001-4007 germanium diode is rated for 1A in forward operation.", 
whys11:"When the working voltage in the opposite direction applied to both sides of the diode is high to a necessary value, the water pipe will suffer thermal breakdown and lose its ability to conduct electricity on one side. For application safety, the reversed maximum operating frequency value is required. For example, the reverse withstand voltage test of IN4001 diode is 50V, and the reverse withstand voltage test of IN4007 is 1000V. ",
whys22: "The amount of reverse current refers to the amount of current passing through the diode in the reverse direction under the action of the diode's normal temperature (25°C) and the reverse maximum working voltage. The smaller the current in the opposite direction, the higher the unidirectional conductivity of the water pipe. It is important to note that the amount of current flowing in the opposite direction is closely related to temperature. For approximately every 10°C increase in temperature, the amount of current in the opposite direction increases several times. For example, the 2AP1 germanium diode has a reverse current of 250uA at 25°C, when the temperature rises to 35°C, the reverse current rises to 500uA, and so on. At 75°C, its reverse current has reached 8mA, which not only loses the characteristics of unidirectional conduction , and also damage the water pipes due to overheating. Another example is the 2CP10 silicon diode, the reverse current is only 5uA at 25°C, and the reverse current is only 160uA when the temperature rises to 75°C. Therefore, silicon diodes have better reliability at high temperatures than germanium diodes.", 

shoujirend:"Personal Data Collection Statement",
shoujirend2:"Today's electronic industry is developing rapidly, and the standard requirements are getting higher and higher, and each customer has different technical requirements. The Group provides a diverse product line to meet the needs of various industries, including automotive electronics, infotainment, IoT applications, motor drives, switching power supplies, and smart lighting. In order to discover cost-effective reference designs, customers can have flexible and changeable choices, cooperate with the market with strong adaptability, and take the lead in occupying the industry opportunities.",
shoujirend3:"Links to other data",
shoujirend4:"Cookie Policy",
shoujirend5:"There is a technology called a ’cookie‘ that can be used to provide you with tailored website information. A cookie is a set of data elements that a website can transmit to your browser and then store it on your system. Some Chinese electronic pages may use cookies so that we can provide you with better service when you return to our website. You can set your browser to notify you when you receive a cookie, giving you the opportunity to decide whether to accept it.",

shoujirend6:"If we provide any link to a third-party website, it is only as a convenience to the owner of the respective third-party website. Links to other third-party websites provided through this website are not under the control of China Electronics, and China Electronics is not responsible for the content, privacy practices, any links or linking procedures of any such linked sites.",
diysho:"first hand information",
diysho2:"All first-hand information on the website of China Electronics (Group) Co., Ltd. is copyrighted by the Group and may not be modified, distributed, downloaded or published without the permission of the Group.",
diysho3:"third party information",
diysho4:"First-hand information All first-hand information on the China Electronics website is copyrighted by the Group, and may not be modified, distributed, downloaded or published without the permission of the Group. Third Party Materials The website contains materials provided by third party materials and obtained from other references or sources, and such materials concerning any person, product or service shall not be deemed to be a recommendation or endorsement by the Group. The Group is not responsible for the up-to-dateness and accuracy of such information and therefore the Group accepts no responsibility for such information. Use and reliance on such information is at your own risk.",
diysho5:"Links to this website", 
diysho6:"Links from this website may link you to other websites, and you acknowledge and agree that the Group is not responsible for the accuracy or validity of any information provided on such linked websites.", 
names:"Name",
gongsimign:"Company Name",
xuni:"message",
emaiti:"Email Alert",
emiss:"Welcome to China Electronics Industry Group Limited email reminder service our system will automatically send you a China Electronics Industry Group Limited announcement",
souxun:"search...",
banquansuo:"Copyright © China Electronics Industry Group Co., Ltd. All rights reserved.",
...enLocale
}


