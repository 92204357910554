<template>
  <div class="footer">
    <div class="title">
      <div class="banner">
        <span>{{$t("Connect")}}</span>
        <span>
          <span class="more" @click="more">{{$t("Contact")}}</span>
        </span>
      </div>
    </div>
    <div class="container">
      <div class="containerBanner">
        <!-- <div class="containerBannerLeft">
          <span class="img2"><img src="../assets/imags/nav/logo.png" alt=""></span>
        </div> -->
        <div class="containerBannerRight">
          <div class="containerBannerRightTitle">{{$t("Headquarters")}}</div>
          <div class="containerBannerRightDetail">
            <div>
              <div><svg version="1.1" id="Layer_1" width="22px" htight="22px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 -4 22 22" style="enable-background:new 0 0 22 22;" xml:space="preserve">
                <svg:style type="text/css">.st0{fill:#414042}</svg:style>
                <path id="XMLID_2619_" class="st0" d="M11,4.5c-2.5,0-4.6,2.1-4.6,4.6c0,1.2,0.8,2.8,1.7,4.4c0.9,1.6,2,3,2.6,3.8
                  c0.1,0.2,0.4,0.2,0.5,0.1c0,0,0,0,0.1-0.1c0.7-0.8,1.7-2.3,2.6-3.8c0.9-1.6,1.7-3.2,1.7-4.4C15.6,6.6,13.5,4.5,11,4.5z M11,6.7
                  c1.2,0,2.2,1,2.2,2.2s-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2C8.8,7.7,9.8,6.7,11,6.7z"></path>
                </svg><span>{{$t("Address")}}：</span></div>
              <div><span>{{$t("Addr_detial")}}</span></div>
            </div>
            <!-- <div>
              <div><svg version="1.1" id="Layer_1" width="22px" htight="22px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 -4 22 22" style="enable-background:new 0 0 22 22;" xml:space="preserve">
                <svg:style type="text/css">.st0{fill:#414042}</svg:style>
                <path id="XMLID_2619_" class="st0" d="M11,4.5c-2.5,0-4.6,2.1-4.6,4.6c0,1.2,0.8,2.8,1.7,4.4c0.9,1.6,2,3,2.6,3.8
                  c0.1,0.2,0.4,0.2,0.5,0.1c0,0,0,0,0.1-0.1c0.7-0.8,1.7-2.3,2.6-3.8c0.9-1.6,1.7-3.2,1.7-4.4C15.6,6.6,13.5,4.5,11,4.5z M11,6.7
                  c1.2,0,2.2,1,2.2,2.2s-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2C8.8,7.7,9.8,6.7,11,6.7z"></path>
                </svg><span>{{$t('LogisticsCentre')}}：</span></div>
              <div><span>{{$t('LogisticsCentreAddr')}}</span></div>
            </div> -->
            <div>
              <div><svg version="1.1" id="Layer_1" width="22px" htight="22px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 -4 22 22" style="enable-background:new 0 0 22 22;" xml:space="preserve">
              <svg:style type="text/css">.st0{fill:#fff}</svg:style>
              <g id="XMLID_2541_" transform="translate(0,-952.36218)">
                <path id="XMLID_2542_"  class="st0" d="M4.6,958.6l6.4,5.2l6.4-5.2H4.6z M3.7,959.2v8.9h14.5v-8.9l-6.9,5.6c-0.2,0.2-0.5,0.2-0.7,0
                  L3.7,959.2z"></path>
              </g>
              </svg><span>{{$t("Email")}}：</span></div>
              <div><span>flora-liu@ceic.co</span><br /> <span>katherine-guo@ceic.co</span><br /> <span>sunny-cai@ceic.co</span></div>
            </div>
            <div>
              <div><svg version="1.1" width="22px" htight="22px" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 -4 22 22" style="enable-background:new 0 0 22 22;" xml:space="preserve">
                <svg:style type="text/css">.st0{fill:#414042}</svg:style>
                <g id="XMLID_2518_">
                  <path id="XMLID_2520_" class="st0" d="M13.6,9.2V8.7c0-0.2-0.2-0.3-0.4-0.3c-1.5,0-3,0-4.5,0c-0.2,0-0.3,0.2-0.3,0.3v0.5
                    c-1.2,0-3.3,4.8-3.3,4.9v1.6c0,0.6,0.3,1.1,0.8,1.1c3.4,0,6.9,0,10.3,0c0.4,0,0.8-0.5,0.8-1.1v-1.6C16.9,14.1,14.8,9.2,13.6,9.2z
                    M15.2,8.4c0,0-0.3-0.7-0.9-0.8c-0.5-0.1-6.1-0.1-6.6,0C7.2,7.7,6.8,8.4,6.8,8.4l0.1,1.7l-3.1,0.1c0,0-0.5-1.7,2.2-3.6
                    c2.6-1.8,7.4-1.8,10.1,0c2.8,1.9,2.2,3.6,2.2,3.6l-3.1-0.1V8.4z M11,10.5c1.2,0,2.1,0.5,2.1,1.3c0,0.7-0.9,1.3-2.1,1.3
                    s-2.2-0.6-2.2-1.3C8.9,11,9.8,10.5,11,10.5z"></path>
                </g>
                </svg><span>{{$t("Phone")}}：</span></div>
              <div><span>010-56540669</span><br /> <span>010-56540537</span><br /> <span>010-56540559</span></div>
            </div>
            <div>
              <div><svg version="1.1" width="22px" htight="22px" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 -4 22 22" style="enable-background:new 0 0 22 22;" xml:space="preserve">
                <svg:style type="text/css">.st0{fill:#fff}</svg:style>
                <g id="XMLID_175_">
                  <path id="XMLID_179_" class="st0" d="M16.2,14.8c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9
                    C15.8,13.9,16.2,14.3,16.2,14.8 M17.8,16.2V9c0,0,0-0.9-0.9-0.9h-1.5V11c0,0.9-0.9,0.9-0.9,0.9H7.6c-0.9,0-0.9-0.9-0.9-0.9V8.1H5.1
                    c0,0-0.9,0-0.9,0.9v7.2c0,0,0,0.9,0.9,0.9h11.7C16.9,17.1,17.8,17.1,17.8,16.2"></path>
                  <path id="XMLID_178_" class="st0" d="M14.5,10V5.8c0,0,0-0.9-0.9-0.9H8.4c0,0-0.9,0-0.9,0.9V10c0,0,0,0.9,0.9,0.9h5.2
                    C13.6,10.9,14.5,10.9,14.5,10"></path>
                </g>
                </svg><span>{{$t("Fax")}}：</span></div>
              <div><span>010-62611672</span><br /> <span>00852-26681899</span></div>
            </div>
          </div>
          <div class="containerBannerRightTitle">{{$t("Sitemap")}}</div>
          <div class="containerBannerRightDetailBottom">
             <!-- <div>{{$t("Manufacturerss")}}</div>
             <div>{{$t("ReferenceSolutions")}}</div>
             <div>{{$t("SalesOffices")}}</div>
             <div>{{$t("TermsCondition")}}</div>
             <div>{{$t("PrivacyPolicy")}}</div>
             <div>{{$t("TermsUse")}}</div> -->
             <el-menu ref="tab" style="height: 60px;display: flex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1" @click="goManu">{{$t('Manufacturerss')}}</el-menu-item>
            <el-submenu index="4">
              <template slot="title">{{$t('ReferenceSolutions')}}</template>
              <el-submenu index="4-1">
                  <template slot="title">{{$t('AutomotiveInfotainment')}}</template>
                  <el-menu-item index="4-1-1">{{$t('BMS')}}</el-menu-item>
                  <el-menu-item index="4-1-2">{{$t('DZC')}}</el-menu-item>
                  <el-menu-item index="4-1-3">{{$t('FCFNV')}}</el-menu-item>
                  <el-menu-item index="4-1-4">{{$t('BLDC')}}</el-menu-item>
                  <el-menu-item index="4-1-5">{{$t('CMOS')}}</el-menu-item>
                  <el-menu-item index="4-1-6">{{$t('Digital')}}</el-menu-item>
              </el-submenu>
              <el-submenu index="4-2">
                  <template slot="title">{{$t('Internet')}}</template>
                  <section v-if="this.$i18n.locale != 'en'">
                    <el-menu-item index="4-2-1">{{$t("Intelligentindustry")}}</el-menu-item>
                    <el-menu-item index="4-2-1">{{$t("Internetsecurity")}}</el-menu-item>
                    <el-menu-item index="4-2-2">{{$t("Equipment")}}</el-menu-item>
                    <el-menu-item index="4-2-3">{{$t("Intelligent")}}</el-menu-item>
                  </section>

                  <el-menu-item index="4-2-4">{{$t("ZigBee")}}</el-menu-item>
                  <el-menu-item index="4-2-6">{{$t("BLE")}}</el-menu-item>
                  <el-menu-item index="4-2-7">{{$t("FG")}}</el-menu-item>
                  <el-menu-item index="4-2-8">{{$t("IoT")}}</el-menu-item>
                  <el-menu-item index="4-2-9" v-if="this.$i18n.locale == 'en'">{{$t("SmartWaste")}}</el-menu-item>

              </el-submenu>
              <el-submenu index="4-3">
                <template slot="title">{{$t("MotorControl")}}</template>
                  <el-menu-item index="4-3-1">{{$t("MotorControl")}}</el-menu-item>
                  <el-menu-item index="4-3-2">{{$t("IPM")}}</el-menu-item>
                  <el-menu-item index="4-3-3">{{$t("HighSpeed")}}</el-menu-item>
                  <el-menu-item index="4-3-4">{{$t("HighVoltage")}}</el-menu-item>
              </el-submenu>
              <el-submenu index="4-4">
                <template slot="title">{{$t("SMPS")}}</template>
                  <el-menu-item index="4-4-1">{{$t("SmartSMPS")}}</el-menu-item>
                  <el-menu-item index="4-4-2">{{$t("DigitalPower")}}</el-menu-item>
                  <el-menu-item index="4-4-3">{{$t("DigitalDimmer")}}</el-menu-item>
                  <el-menu-item index="4-4-4">{{$t("MultiFunction")}}</el-menu-item>
                  <el-menu-item index="4-4-5">{{$t("LEDDimming")}}</el-menu-item>
              </el-submenu>
            </el-submenu>
            <el-menu-item index="2" @click="office">{{$t('SalesOffices')}}</el-menu-item>
            <!-- <el-menu-item index="3" @click="tiaojian">{{$t('TermsCondition')}}</el-menu-item> -->
            <el-menu-item index="5" @click="xiaoshou">{{$t('PrivacyPolicy')}}</el-menu-item>
            <el-menu-item index="6" @click="useJS">{{$t('TermsUse')}}</el-menu-item>
          </el-menu>
          </div>
          <div class="menu" @click="emmit"><i class="el-icon-s-fold"></i></div>
        </div>
      </div>
    </div>
    <div class="foot">
      <span>{{$t('banquansuo')}}</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    more() {
      this.$router.push('/headquarters')
    },
    handleSelect(index, indexPath) {
      // setTabSelect(indexPath[0])
      this.$store.commit('setTabSelet', indexPath[0])
      console.log(index, indexPath);
      if(indexPath[0] !== '4') return
      switch(indexPath[2]) {
        case '4-1-1':
          this.$router.push('/BMS')
          break;
        case '4-1-2':
          this.$router.push('/car')
          break;
        case '4-1-3':
          this.$router.push('/newEnergy')
          break;
        case '4-1-4':
          this.$router.push('/waterPump')
          break;
        case '4-1-5':
          this.$router.push('/CMOS')
          break;
        case '4-1-6':
          this.$router.push('/radio')
          break;
        case '4-2-1':
          this.$router.push('/AI')
          break;
        case '4-2-2':
          this.$router.push('/inter')
          break;
        case '4-2-3':
          this.$router.push('/device')
          break;
        case '4-2-4':
          this.$router.push('/zhihui')
          break;
        case '4-2-5':
          this.$router.push('/ZIGbEE')
          break;
        case '4-2-6':
          this.$router.push('/BLE')
          break;
        case '4-2-7':
          this.$router.push('/4G')
          break;
        case '4-2-8':
          this.$router.push('/NB')
          break;
        case '4-2-9':
          this.$router.push('/bluetooth')
          break;
        case '4-3-1':
          this.$router.push('/washer')
          break;
        case '4-3-2':
          this.$router.push('/IPM')
          break;
        case '4-3-3':
          this.$router.push('/lowTension')
          break;
        case '4-3-4':
          this.$router.push('/highHanded')
          break;
        case '4-4-1':
          this.$router.push('/powerSupply')
          break;
        case '4-4-2':
          this.$router.push('/numberSupply')
          break;
        case '4-4-3':
          this.$router.push('/programming')
          break;
        case '4-4-4':
          this.$router.push('/LED')
          break;
        case '4-4-5':
          this.$router.push('/NFC')
          break;
        default:
          this.$router.push('/')
      }
    },
    goManu() {
      this.$router.push('/maker')
    },
    office() {
      this.$router.push('/office')
    },
    tiaojian() {
      this.$router.push('/tiaojian')
    },
    xiaoshou() {
      this.$router.push('/xiaoshou')
    },
    useJS() {
      this.$router.push('/useJS')
    },
    emmit() {
      this.$emit('online')
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 750px) {
    .container {
      height: auto !important;
      text-align: center;
    }
    .containerBanner {
        flex-direction: column !important;
        height: auto !important;
    }
    .containerBannerRightDetailBottom {
      display: none !important;

    }
    .menu {
      display: block !important;
    }
     .img2 {
      display: inline-block;
      margin: 0 auto 20px !important;
    }
}
  .footer {
    width: 100%;
    .title {
      width: 100%;
      height: 80px;
      background-color: #0055a3;
      .banner {
        margin: 0 auto;
        max-width: 1200px;
        line-height: 80px;
        display: flex;
        span {
          min-width: 280px;
        }
        span:nth-child(1){
          flex: 1;
          font-size: 26px;
          color: #fff;
          font-weight: bold;
        }
        span:nth-child(2) {
          flex: 1;
           position: relative;
          .more {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 50%;
            font-size: 16px;
            transform: translateY(-50%);
            width: 172px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 4px;
            background-color: #4F5761;
          }
        }
      }
    }
    .container {
      width: 100%;
      height: 330px;
      box-sizing: border-box;
      background: url('../assets/imags/footer/footer-bg.jpg');
      padding: 40px 0;
      .containerBanner {
        max-width: 1200px;
        margin: 0 auto;
        // display: flex;
        height: 100%;
        .containerBannerLeft {
          text-align: center;
          // flex: 3;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-content: center;
          span:nth-child(1) {
            flex: 1;
            width: 233px;
            height: 32px;
            margin-bottom: 20px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          span:nth-child(2) {
            flex: 10;
          }
          span:nth-child(3) {
            flex: 1;
            color: #fff;
            font-size: 12px;
          }
        }
        .containerBannerRight {
          // flex: 13;
          height: 100%;
          .containerBannerRightTitle {
            height: 35px;
            font-size: 18px;
            font-weight: 600;
            color: #fff;
            border-bottom: 1px solid #fff;
          }
          .containerBannerRightDetail {
            display: flex;
            padding-top: 12px;
            margin-bottom: 10px;
            min-width: 710px;
            color: #fff;
            &>div {
              flex: 1;
              div:nth-child(1) span{
                font-size: 14px;
                color: #fff;
              }
              div:nth-child(2) {
                padding-right: 9px;
              }
              div:nth-child(2) span{
                font-size: 12px;
                color: #fff;
              }
            }
          }
          ::v-deep.containerBannerRightDetailBottom {
            color: #fff;
            display: flex;
            .el-menu.el-menu--horizontal {
                border: unset;
                background-color: transparent;
              }
              .el-menu--horizontal .el-menu-item:not(.is-disabled):hover,.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
                background-color: unset;
                color: #0055a3;
              }
              .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
                background-color: unset !important;
              }
              .el-menu--horizontal>.el-menu-item.is-active {
                border-bottom : unset;
                color: #0055a3;
              }
              .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
                border: unset;
                color: unset;
              }
              .el-menu-item {
                background-color: unset !important;
              }
              .el-menu--horizontal>.el-menu-item, .el-menu--horizontal>.el-submenu .el-submenu__title{
                color: #fff !important;
              }
              .el-menu-item.is-active {
                color: unset !important;
              }
            div {
              font-size: 14px;
            }
          }
        }
      }
    }
    .menu {
      display: none;
      flex: 6;
      // float: cen;
      text-align: center;
      line-height: 80px;
      i {
        font-size: 34px;
      }
    }
    .foot {
      height: 60px;
      line-height: 60px;
      font-size: 14px;
      color: #4F5761;
      text-align: center;
      background-color: #fff;
    }
  }
</style>
