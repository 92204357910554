import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
export default {
  zhf: "繁體中文",
  zh: "简体中文",
  EN: "EN",
  Home:"首页",
  AboutWillas:"有关中国电子",
  AboutUs:"关于我们",
  BusinessScope:"业务范围",
  Milestones:"发展里程",
  OMV:"抱负、使命、信念",
  BusinessStructure:"业务架构",
  BDSM:"董事会及高级管理层",
  Careers:"人才招聘",
  Manufacturers:"制造商品牌",
  ReferenceSolutions:"参考方案",
  SchemeQuery:"方案查询",

  AutomotiveInfotainment:"汽车电子/信息娱乐",
  BMS:"BMS 系统",
  DZC:"车门域控制系统",
  FCFNV:"新能源矢量冷凝风扇",
  BLDC:"无刷变频水泵",
  CMOS:"CMOS镜头",
  Digital:"数字收音机+音效+功放",
  Internet:"物联网应用",
  ZigBee:"ZigBee / BLE 模组(STM32WB)",
  laji:"智慧垃圾治理系统",
  mozu:"BLE 模组(BLUENGR)",
  mozufour:"4G 模组",
  mozunb:"NB-IoT 模组",
  bluur:"蓝牙断路器",
  gunji:"无位置检测滚筒洗衣机",
  ipmj:"IPM 电机驱动",
  gsdianji:"高速低压矢量电机驱动",
  gaoqudong:"高压集成控制电机驱动",

  BLE:"BLE 模组(BLUENGR)",
  FG:"4G 模组",
  IoT:"NB-IoT 模组",
  Bluetooth:"蓝牙断路器",
  Intelligent:"智慧垃圾治理系统",
  Equipment:"设备管理系统",
  Internetsecurity:"物联网安全技术",
  Intelligentindustry:"智能工业",
  SMPS:"开关电源/智能照明",
  SmartSMPS:"智能电源电表",
  DigitalPower:"数字可编程调光器",
  MultiFunction:"多功能调光LED驱动",
  LEDDimming:"带NFC控制LED调光驱动",



  MotorControl:"智能工业",
  Sensorless:"无位置检测滚筒洗衣机",
  IPM:"无位置检测滚筒洗衣机",
  HighSpeed:"高速电机驱动",
  HighVoltage:"高压集成控制电机驱动",





  InvestorRelations:"投资者关系",
  ContactUs:"联系我们",
  IoTDevice:"产品应用",
  IoTDevice2: '微芯片产品',
  ComponentMarket:"紧贴电子元器件市场",
  NEW:"最新资讯",
  TypeC:"纳米发电来了",
  Dexerials:"什么是理想二极管？?",
  Readmore:"阅读更多",
  Publication:"专注于检测",
  Joinus:"视员工为集团不可或缺的重要伙伴，共同面对市场上的种种挑战。",
  PublicationD:"欢迎下载公司最新简介书",
  CorporateNews:"行业新闻",
  Overseas:"预计2020年半导体市场增长5.1%",
  Regulatory:"全球半导体组装市场将增长",
  Announcement:"纯晶圆代工市场自 2014 年以来增长最为强劲",
  More:"更多新闻",
  yf:"2020年10月28日 ",
  AboutTitle:"公司简介",
  AboutTitle_C:"中国电子工业集团有限公司 （简称CEIC）专注于为客户分销电子元器件，服务于全球消费、汽车、工业、医疗器械、计算、电信与交通、航空航天等行业的客户。",
  AboutTitle_2:"企业文化",
  AboutTitle_C2:"为了满足全球不同地区客户的需求，中国电子工业集团有限公司拥有一支全方位的采购和销售团队，我们的客户可以及时获得最准确的信息。 物流中心专门设在香港和深圳。 所有货物在发货前都经过严格检验，以确保原货和新货。 采用快递门到门服务，确保紧急货物3日内送达客户，满足客户紧急需求",
  Connect:"与我们的方案专家联络",
  Contact:"了解更多",
  OnWeChat:"欢迎关注微信服务号",
  Headquarters:"公司总部",
  Address:"北京办事处",
  Addr_detial:"中国北京市丰台区 南四环186号三区4号楼7M 11-13室",
  Email:"邮箱",
  wuliuCenter: "物流中心",
  wuliuAddress: "香港新界沙田小瀝源安平街8號偉達中心6樓601室",
  Phone:"电话",
  Fax:"传真",
  disn:"电邮",

  Sitemap:"网站地图",
  Manufacturerss:"制造商",
  SalesOffices:"销售办事处",
  TermsCondition:"销售条款",
  PrivacyPolicy:"隐私政策",
  TermsUse:"使用条款",
  HLC:"集团总部及物流中心",
  SEHK:"集团总部及物流中心",
  SGX:"SGX投资者",
  EmailMe:"电邮提醒",



  Wide:"多元化的代理产品",
  WideC:"现今电子产业的发展日新月异，标准要求也越来越高，而每位客户对于技术要求也不尽相同。集团提供多元化的产品线，满足各行各业所需，包括汽车电子、信息娱乐、物联网应用、电机驱动、开关电源及智能照明等等应有尽有。从而发掘高性价比的参考设计，让客户拥有灵活多变的选择，以强大的适应性配合市场，率先佔领行业先机。",
  Reference:"专业的应用设计",
  ReferenceC:"作为行业的探索者，除了密切留意市场最新动向，为不同的客户提供高端的电子元器件之外，集团高质素的工程师队伍一直努力不懈地开拓崭新的设计理念，提升产品应用技术，力求在短期内完成研发并满足不同客户的技术要求，缩短客户研发週期，大大加快推出市场的时间，强化客户在市场的竞争力。",
  ValueAdded:"专业的应用设计",
  ValueAddedC:"我们深信优越服务至为重要，并竭力为客户提供增值服务。为进一步提升集团的营运效率，集团利用企业管理整合系统SAP，再配合资讯科技部门从中不断改良，以提升供应链管理、存货、销售和风险管理的运作；加上专业后勤及物流人员的配合，确保我们在现今激烈竞争的环境下，仍能一贯地为客户提供快捷准时的送货服务。",
  ConvertTomorrow:'创新潜能拓展未来 · 瞻望中华掌握先机',
  OurMission:'使命',
  AsiaVision:'放眼亚洲',
  AsiaVisionC:'中国电子产业集团有限公司在大中华地区的稳固基础和销售网路的优势，以庞大的规模和专业的服务，发掘与亚洲区各地的合作机会。',
  EfficiencyAdvancement:'增强效率',
  EfficiencyAdvancementC:'内部系统及流程虽然已经成熟，但我们仍不断追求更卓越的竞争力，提高各部门效率，将人力及资源最优化，于短期内完成研发并满足不同客户的技术要求，巩固及提升集团在市场中的位置，从而拓展销售及市场业务。',
  ServiceOptimization:'优化服务',
  ServiceOptimizationC:'积极的力求创新及服务优化，透过上下一心的团队来提升公司价值，成为更多供应商与客户最值得信赖和合作的伙伴。',
  Insightful:'推动未来',
  InsightfulC:'强大的技术部门始终与客户站在一起，从客户的角度出发，针对他们的成本及功能需求，致力将崭新的开发理念融入高增值的市场需求和预算当中，提高系统设计能效，为客户推动具成本效益的设计方案。',
  OurValues:'信念',
  Trust:'信赖伙伴',
  TrustC:'致力于和供应商及客户建立信赖及承诺，搜罗更丰富的尖端产品供应，发掘更新更灵活的技术支持方案，共同面对市场上的种种挑战。',
  Human:'以人為本',
  HumanC:'集团拥有高质素及经验丰富的员工，既珍惜这些难能可贵人才，也鼓励前线员工坦诚开放和创新精神，在不断学习的过程中快速地成长和进步。',
  Opportunities:'创造机会',
  OpportunitiesC:'视员工和客户为我们的内部和外部不可或缺的重要伙伴，除了主动预期及满足他们各项多变的需求外，并为他们提供全方位的资讯服务，给予优势、弹性和成功机会。',
  Integrity:'坚守承诺',
  IntegrityC:'坚守对顾客、合作伙伴及员工的承诺，以开放和忠诚的态度作为经营之道。力求创新的同时，并重视优良的品质的保证，不论是产品或解决方案，都为客户提供高于期望的优质产品，建立良好的品质及服务信誉。',

  Director:'非执行董事',
  Leung:'梁振华',
  Chairman:'主席兼非執行董事',
  Executive:'Executive Directors',
  HonKarChun:'韩家振',
  LeungHonShing:'梁汉成',
  LeungChiHangDaniel:'梁智恒',

  HonKarChunZ:'董事总经理兼执行董事',
  LeungHonShingZ:'执行董事、首席财务长兼公司秘书',
  LeungChiHangDanielZ:'执行董事',
  LogisticsCentre:'物流中心',
  LogisticsCentreAddr:'香港新界沙田小沥源安平街8号伟达中心6楼601室',

  carInfo:"汽车电子 / 信息娱乐",
  SmartIndustry:"智能工业",
  switch:"开关电源/智能照明",
  introduce:"介绍",
  introducec:"BMS是衔接新能源汽车电池包、整车控制和电机的重要纽带，负责新能源汽车电池包的全面管理，主要包括三大核心功能，分别为电池包数据採集、电池包状态估算、电芯单体一致性维护。",
  ProgramFeatures:"方案特色",
  photo:"框图/评估板/相片",
  moreReferenceSolution:"更多解決方案",
  ReferenceSchemeCon:"我们致力将崭新的开发理念融入高性价比的参考设计中, 为客户推动具成本效益的更多解决方案。",
  ContactUsCon:"如果您对此方案感兴趣，请联繫我们瞭解专业设计意见。",
  Ltd:"中国电子产业集团有限公司",
  grid:"销售网络",
  btnLianxi:"请按此处联系我们的销售办事处",
  zongLtd:"总公司",
  jituanc:"集团查询",
  xiaosouc:"销售查询",
  submit:"提交",
  yingyin:"应用范围",
  dianboa:"智能电表电源",
  shuzidian:"数字电源",
  shuzikeitao:"数字可编程调光器",
ledduo:"多功能调光 LED 驱动",
nfcdu:"带 NFC 控制 LED 调光驱动",
jituanzon:"集团总部",
xg:"香港",
jituannew:"集团新闻",
chezai:"车载电子",
xiaofeichan:"消费类电子产品",
tongxuns:"通讯网络",
dongli:"动力总成和混合动力系统",
dongli2:"底盘和安全",
dongli3:"车身电子",
dongli4:"车载信息娱乐系统",
dongli5:"仪表板",
dongli6:"高级驾驶辅助",
dongli7:"车联网",


kechuan:"可穿戴设备",
kechuan1:"生活娱乐",
kechuan2:"电视",
kechuan3:"游戏视频和音频",
kechuan4:"家用设备",
kechuan5:"广播机顶盒",


chuanshus:"光接入/传输网络",
chuanshus1:"无线路由器",
chuanshus2:"电视传输",
chuanshus3:"无线局域网",
chuanshus4:"光通信",
chuanshus5:"5G应用",
ppp:"Microchip Technology Inc. 是微控制器和模拟半导体的领先供应商，为全球数千种不同的客户应用提供低风险的产品开发、更低的系统总成本和更快的上市时间。Microchip 总部位于亚利桑那州钱德勒，提供出色的技术支持以及可靠的交付和质量。",
adipp:"ADI 的模拟、混合信号和数字信号处理 (DSP) 集成电路 (IC) 在将光、声音、温度、运动和压力等现实世界现象转换、调节和处理成电信号以实现可用于各种电子设备。ADI 是电子制造商中高性能的代名词，我们与客户合作以定义最佳的用户体验质量。",

banpp:"STMicroelectronics 是一家全球性的独立半导体公司，在开发和提供微电子应用领域的半导体解决方案方面处于领先地位。硅和系统专业知识、制造实力、知识产权 (IP) 组合和战略合作伙伴的无与伦比的结合使公司处于片上系统 (SoC) 技术的最前沿，其产品在实现当今的融合趋势中发挥着关键作用。",
depp:"德州仪器 (TI) 是一家开发模拟 IC 和嵌入式处理器的全球半导体设计和制造公司。通过聘请世界上最聪明的人才，TI 创造了塑造技术未来的创新。今天，TI 正在帮助超过 100,000 名客户改变未来。",
avspp:"AVX Corporation 是全球无源电子元件和互连产品行业公认的领导者。AVX Corporation 每年都会接受挑战，帮助设计工程师利用 AVX 的尖端研究、设计专业知识和材料技术为客户创造卓越的产品，以提高性能并降低总成本。在四大洲 17 个国家/地区的全球制造能力使 AVX 能够在全球范围内满足客户的需求。",

gonsijianjieshu:"公司简介书",
zhuanzhujian:"专注于检测",
waibujian:"外部视觉检查",
wibupp:"外观外观检查是检查零件数量、内包装、干燥和外包装、真空包装是否完好、有斑纹的热压标记清晰并保持高度一致性、制造商的标志位置和印刷字体（包括日期代码和国家/地区）根据制造商的标准。",
xinpian:"芯片检测",
xinpianpp:"芯片检测要求元件具有相同的形状/颜色，元件的正面和背面具有相同的光洁度，没有划痕。验证二次涂层，引脚状态，抛光标记，标记上的凸点。定位孔准确清晰，Pin/ball 颜色和形状正常（无氧化、变形、毛刺、损坏、污染等）。外形尺寸、引脚位置、引脚间距、引脚长度与封装外形一致。",
bufenhogn:"部分功能测试与第三方测试机构合作",
bufenhognpp:"第三方考试中心 去盖测试 X 射线 电气测试 功能测试",
zhiliang:"质量控制",
zhiliangpp:"中国电子相信正规的供应渠道是基础，我们始终坚持对每一批进出库的产品进行检测。所有产品必须按照公司进出库程序进行检测。同时，我们有权威的第三方检验机构作为技术支持，帮助我们完成出境货物的样品检验。",

honnrhnzz:"功能测试",
honnrhnzz2:"验证无源元件以确保没有开口或缺陷；确保组件性能符合制造商的规格（电阻/电容/电感）。",

baozhuangwu:"包装与物流",
baozhuangwu3:"测试服务的最后一步是包装和交付，我们与其他测试项目一样重视，所有产品必须以 ESD 标准包装运输，没有缺陷和/或修改。我们认识到及时和安全地向客户交付组件的重要性。我们提供完整的包装和运输服务，协助您将货物运送到您指定的目的地。",
wome:"我们为全球行业提供电子元器件和价值服务",

err:"E-9963高精度AFE提供精准电芯电压採集",
err2:"E-SPC58高性能MCU提供强大运算能力",
err3:"E-9963高精度AFE提供精準電芯電壓採集",

yewuxie:"业务协同化",
yewuxie2:"将车辆管理，人员管理，设施管理，任务处理，事件问题，地图，实时通讯等业务协同，打通业务之间和各个部门的关联，形成协同管理快速机制。",

wuchu:"无处不在的纳米发电机",
wuchu2:"它的出现，彻底打破了人们对“发电机”尺寸的认识极限。纳米发电机可以实现对环境中极微量机械能的收集和利用。例如，空气或水的流动、发动机的转动、机器的运转、人走路时肌肉的伸展、脚踩地面，甚至呼吸引起的各种频率的噪音，人体内的心跳或血流。身体某处压力的轻微变化可以驱动纳米发电机发电。因此，纳米发电机理论为当前物联网、传感器网络和大数据的实现提供了理想的动力解决方案。",
wuchu3:"目前，纳米发电机可以分为三类：第一类是压电纳米发电机；第二类是摩擦纳米发电机；第三类是热释电纳米发电机。一般用于生物医学、军事、无线通信、无线传感等领域。",
wuchu4:"在当前可伸缩、可穿戴电子设备快速发展的时代，柔性机械能采集装置的研究具有非常重要的价值和意义。近年来，利用柔性材料替代高分子商业薄膜和金属片组装柔性摩擦纳米发电机的研究成为亮点。近年来，柔性摩擦纳米发电机的研究成果较多。",
wuch2:"精确测试微小信号充满挑战",
wuch3:"由于纳米发电的技术特点，在研究过程中需要对单位面积机械能产生的电能进行测试。测试产生的电压、微小电流和功率信号基本都是几伏甚至几十伏，电流一般在uA甚至nA级，功率在mW甚至uW级。小电流和功率信号难以准确测试，对测试仪器的精度和稳定性要求非常高。泰克吉时利专注于小电信号的测量。历史上许多诺贝尔物理学奖获得者都使用并信赖吉时利的测试仪器。在纳米发电的研究中，吉时利的产品仍然是业界的首选，尤其是在小信号测试方面。",
wuch4:"测量灵敏度的理论极限取决于电路中电阻产生的噪声。电压噪声与电阻、带宽和绝对温度乘积的平方根成正比。从图中可以看出，源电阻限制了电压测量的理论灵敏度，即当源电阻为1Ω的1uV信号可以准确测量时，如果信号的源电阻变为1TΩ，测量将变为不可能的。因为当源电阻为1MΩ时，1uV的测量值已经接近理论极限。这时，用普通的数字万用表是不可能完成这类测量的。选择合适的仪器是确保准确测试小信号的先决条件。",
wuch5:"小电流信号测试",
wuch6:"方法：采用绝缘材料纳米发电技术，一般源电阻在GΩ级，测试电流在pA级，所以业界采用静电计6514+斯坦福SR570（低噪声电流前置放大器）+专用采集生成当前数据集合的软件。",
 

whys:"什么是理想二极管？?",
whys2:"二极管也叫晶体二极管，俗称二极管，另外，还有最初的真空泵电子二极管；这是可以单方面传导电流的电子元件。半导体材料二极管内部有一个PN结和两个接线端子。这种类型的电子元件根据附加工作电压的方向具有单向导电性。",
whys3:"一般来说，晶体二极管是通过烧制p型半导体材料和n型半导体材料制成的pn结页。在页面的两侧产生空间电荷层以形成静电场。当附加工作电压为零时，由于pn结两侧载流子浓度的不同导致外扩散电流量与构建静电场引起的漂移电流量相同，并且处于电平衡状态，这都是归一化的。二极管特性。",
whys4:"二极管效率",
whys5:"二极管是最常见的电子元件中的第一个。其主要特点是单向导电，即一定量的电流只能通过二极管的一个方向。二极管的作用是整流电路和检测电源电路。稳压电路，各种解调电源电路。", 
whys6:"二极管原理", 
whys7:"晶体二极管是由p型半导体材料和n型半导体材料制成的pn结。在页面的两侧产生空间电荷层并建立静电场。当没有发现额外的工作电压时，pn结两侧载流子浓度不同引起的外扩散电流量与建立静电场引起的漂移电流量相同，处于电平衡状态。当外部有正向工作电压参考点时，外部静电场和建立的静电场的相互抑制作用增加了载流子的外部扩散电流量，从而产生了正向电流量。当存在反方向的外部工作电压参考点时，外部静电场和构造静电场得到有效加强，与反方向参考点工作电压值无关的反向饱和电流I0为在相反方向的工作电压范围内产生。当反方向的附加工作电压高到必要水平时，pn结空间电荷层中的电场强度超过临界点，导致载流子在整个过程中生长，导致许多电子器件出现空化现象, 导致一个非常大的标度值反向的热击穿电流量称为二极管的热击穿条件。pn结的反向热击穿可分为齐纳热击穿和雪崩击穿。", 
whys8:"二极管基本参数", 
whys9:"是指二极管长期连续工作时所允许的较大的正向平均电流，其值与PN结的总面积和外接热管的散热标准有关。由于电流量根据水管的时间使模具变热，温度升高，当温度超过允许下限时（硅管最高141，锗管最高90），模具会过热而损坏。因此，在热管散热标准的要求下，二极管应用不一定要超过二极管的较大整流电流。例如，常见的 IN4001-4007 锗二极管在正向工作时的额定电流为 1A。", 
whys11:"当加在二极管两侧的反方向工作电压高到必要值时，水管会发生热击穿，失去单边导电工作能力。为保证应用安全，需要反向的最大工作频率值。例如IN4001二极管的反向耐压测试为50V，IN4007的反向耐压测试为1000V。", 
whys22:"反向电流量是指在二极管常温（25℃）和反向最大工作电压的作用下，反向通过二极管的电流量。反方向的电流越小，水管的单向电导率越高。特别要注意的是，相反方向的电流量与温度密切相关。大约温度每升高 10°C，相反方向的电流量就会增加数倍。比如2AP1锗二极管，25℃时反方向电流250uA，温度上升到35℃，反方向电流上升到500uA，以此类推，75℃，它的反方向电流已经高达8mA，不仅失去了单向导电的特性，还会因过热而损坏水管。又如2CP10型硅二极管，25℃时反方向电流仅为5uA，升温至75℃时反方向电流仅为160uA。因此，硅二极管在高温下比锗二极管具有更好的可靠性。", 

shoujirend:"收集个人资料声明",
shoujirend2:"现今电子产业的发展日新月异，标准要求也越来越高，而每位客户对于技术要求也不尽相同。集团提供多元化的产品线，满足各行各业所需，包括汽车电子、信息娱乐、物联网应用、电机驱动、开关电源及智能照明等等应有尽有。从而发掘高性价比的参考设计，让客户拥有灵活多变的选择，以强大的适应性配合市场，率先佔领行业先机。",
shoujirend3:"与其他资料之间的连结",
shoujirend4:"Cookie政策",
shoujirend5:"有一种称为“Cookie”的技术，可用于为您提供量身打造的网站资讯。Cookie 是一组数据元素，网站可以传送到您的浏览器，随后储存在您的系统中。某些中国电子页面可能会使用 Cookie，以便在您返回我们网站时，我们能为您提供更好的服务。您可以将浏览器设定为在收到 Cookie 时通知您，从而让您有机会决定是否接受它。",
shoujirend6:"如果我们提供了连接到第三方网站的任何连结，它只是作为一项通向相应第三方网站所有者的便利措施。经由本网站提供的其他第三方网站连 结，并未处于中国电子的控管之中，对于任何此类连结网站的内容、隐私惯例、任何连结或连结程序，中国电子概不负责。",

diysho:"第一手资料",
diysho2:"中国电子(集团)有限公司网站内的所有第一手资料，均属本集团版权所有，未经本集团许可，不得修改、散佈、下载及刊出。",
diysho3:"第三方资料",
diysho4:"第一手资料中国电子网站内的所有第一手资料，均属本集团版权所有，未经本集团许可，不得修改、散佈、下载及刊出。第三方资料网站中含有由第三方资料提供及从其他参考资料或来源获取的资料，此类涉及任何人士、产品或服务的资料，不得视为本集团推荐或认可。本集团不会负责此类资料的最新性及准确性，因此本集团对该等资料不承担任何责任。使用及依赖该等资料，风险将由阁下自负。",
diysho5:"本网站的连接", 
diysho6:"本网站的连接可将阁下连往其他网址，而阁下承认并同意，本集团对此类连接网址所提供的任何资讯的准确性或有效性，不负任何责任。", 
names:"姓名",
gongsimign:"公司名称",
xuni:"讯息",
emaiti:"电子邮件提醒",
emiss:"欢迎使用中国电子工业集团有限公司邮件提醒服务我们的系统会自动向您发送中国电子工业集团有限公司的公告",
souxun:"搜寻...",
banquansuo:"版权所有©中国电子产业集团有限公司保留所有权利。",
ename:'英文名',
yuangongxinxi:'员工信息',
closebtn:'关 闭',
...zhLocale
}


