import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
export default {
  zhf: "繁體中文",
  zh: "简体中文",
  EN: "EN",
  Home:"首頁",
  AboutWillas:"有關中國電子",
  AboutUs:"關於我們",
  BusinessScope:"業務範圍",
  Milestones:"發展里程",
  OMV:"抱負、使命、信念",
  BusinessStructure:"業務架構",
  BDSM:"董事會及高級管理層",
  Careers:"人才招聘",
  Manufacturers:"製造商品牌",
  ReferenceSolutions:"參考方案",
  SchemeQuery:"方案查詢",
  AutomotiveInfotainment:"汽車電子/信息娛樂",
  BMS:"BMS 系統",
  DZC:"車門域控制系統",
  FCFNV:"新能源矢量冷凝風扇",
  BLDC:"無刷變頻水泵",
  CMOS:"CMOS鏡頭",
  Digital:"數字收音機+音效+功放",
  Internet:"物聯網應用",
  ZigBee:"ZigBee / BLE 模組(STM32WB)",

  BLE:"BLE 模組(BLUENGR)",
  FG:"4G 模組",
  IoT:"NB-IoT 模組",
  Bluetooth:"藍牙斷路器",
  Intelligent:"智慧垃圾治理系統",
  Equipment:"設備管理系統",
  Internetsecurity:"物聯網安全技術",
  Intelligentindustry:"智能工業",


  MotorControl:"電機驅動",
  Sensorless:"無位置檢測滾筒洗衣機",
  IPM:"IPM電機驅動",
  HighSpeed:"高速電機驅動",
  HighVoltage:"高壓集成控製電機驅動",
  SMPS:"開關電源/智能照明",
  SmartSMPS:"智能電源電表",
  DigitalPower:"數字電源",
  DigitalDimmer:"數字可編程調光器",
  MultiFunction:"多功能調光LED驅動",
  LEDDimming:"帶NFC控制LED調光驅動",



  InvestorRelations:"投資者關系",
  ContactUs:"聯係我們",
  IoTDevice:"產品應用",
  IoTDevice2: '微芯片產品',
  ComponentMarket:"緊貼電子元器件市場 ",
  NEW:"最新資訊",
  TypeC:"納米發電來了",
  Dexerials:"什么是理想二极管？?",
  Readmore:"閱讀更多",
  Publication:"專註於檢測",
  Joinus:"視員工為集團不可或缺的重要夥伴，共同面對市場上的種種挑戰。",
  PublicationD:"歡迎下載公司最新簡介書",
  CorporateNews:"行业新闻",
  Overseas:"預計2020年半導體市場增長5.1%",
  Regulatory:"全球半導體組裝市場將增長",
  Announcement:"純晶圓代工市場自 2014 年以來增長最為強勁",
  More:"更多新聞",
  yf:"2022年6月14日",
  AboutTitle:"公司簡介",
  AboutTitle_C:"中國電子工業集團有限公司 （簡稱CEIC）專註於為客戶分銷電子元器件，服務於全球消費、汽車、工業、醫療器械、計算、電信與交通、航空航天等行業的客戶。",
  AboutTitle_2:"企業文化",
  AboutTitle_C2:"中国电子产业集团有限公司為照顧到世界各地不同地區客戶的需求，我們每周7天，每天24小時全方位運作的采購銷售團隊，讓我們的客戶及時得到最準確的信息。專門在香港、深圳設立物流中心，所有貨品出貨前經過嚴格檢驗，保證原廠原裝新貨。采用門對門特快專遞服務，保證緊急貨物3天內送達客戶手中滿足客戶急需。",
  Connect:"與我們的方案專家聯絡",
  Contact:"了解更多",
  OnWeChat:"歡迎關註微信服務號",
  Headquarters:"公司總部",
  Address:"北京辦事處",
  Addr_detial:"南四環186號三區4號樓7M 11-13室 中國北京市豐臺區",
  Email:"Email",
  wuliuCenter: "物流中心",
  wuliuAddress: "香港新界沙田小瀝源安平街8號偉達中心6樓601室",
  Phone:"電話",
  Fax:"傳真",
  disn:"電郵",
  Sitemap:"網站地圖",
  Manufacturerss:"製造商",
  SalesOffices:"銷售辦事處",
  TermsCondition:"銷售條款",
  PrivacyPolicy:"隱私政策",
  TermsUse:"使用條款",
  HLC:"集團總部及物流中心",
  SEHK:"SEHK投資者",
  SGX:"SGX投資者",
  EmailMe:"電郵提醒",

  Wide:"多元化的代理產品",
  WideC:"現今電子產業的發展日新月異，標準要求也越來越高，而每位客戶對於技術要求也不盡相同。集團提供多元化的產品線，滿足各行各業所需，包括汽車電子、信息娛樂、物聯網應用、電機驅動、開關電源及智能照明等等應有盡有。從而發掘高性價比的參考設計，讓客戶擁有靈活多變的選擇，以強大的適應性配合市場，率先佔領行業先機。",
  Reference:"專業的應用設計",
  ReferenceC:"作為行業的探索者，除了密切留意市場最新動向，為不同的客戶提供高端的電子元器件之外，集團高質素的工程師隊伍一直努力不懈地開拓嶄新的設計理念，提升產品應用技術，力求在短期內完成研發並滿足不同客戶的技術要求，縮短客戶研發週期，大大加快推出市場的時間，強化客戶在市場的競爭力。",
  ValueAdded:"优越的增值服务",
  ValueAddedC:"我們深信優越服務至為重要，並竭力為客戶提供增值服務。為進一步提升集團的營運效率，集團利用企業管理整合系統SAP，再配合資訊科技部門從中不斷改良，以提升供應鏈管理、存貨、銷售和風險管理的運作；加上專業後勤及物流人員的配合，確保我們在現今激烈競爭的環境下，仍能一貫地為客戶提供快捷準時的送貨服務。",
  ConvertTomorrow:'創新潛能拓展未來 · 瞻望中華掌握先機',
  OurMission:'使命',
  AsiaVision:'放眼亞洲',
  AsiaVisionC:'中国电子产业集团有限公司在大中華地區的穩固基礎和銷售網路的優勢，以龐大的規模和專業的服務，發掘與亞洲區各地的合作機會。',
  EfficiencyAdvancement:'增強效率',
  EfficiencyAdvancementC:'內部系統及流程雖然已經成熟，但我們仍不斷追求更卓越的競爭力，提高各部門效率，將人力及資源最優化，於短期內完成研發並滿足不同客戶的技術要求，鞏固及提升集團在市場中的位置，從而拓展銷售及市場業務。',
  ServiceOptimization:'優化服務',
  ServiceOptimizationC:'積極的力求創新及服務優化，透過上下一心的團隊來提升公司價值，成為更多供應商與客戶最值得信賴和合作的伙伴。',
  Insightful:'推動未來',
  InsightfulC:'強大的技術部門始終與客戶站在一起，從客戶的角度出發，針對他們的成本及功能需求，致力將嶄新的開發理念融入高增值的市場需求和預算當中，提高系統設計能效，為客戶推動具成本效益的設計方案。',
  OurValues:'信念',


  Trust:'信賴伙伴',
  TrustC:'致力於和供應商及客戶建立信賴及承諾，搜羅更豐富的尖端產品供應，發掘更新更靈活的技術支持方案，共同面對市場上的種種挑戰。',
  Human:'以人為本',
  HumanC:'集团拥有高质素及经验丰富的员工，既珍惜这些难能可贵人才，也鼓励前线员工坦诚开放和创新精神，在不断学习的过程中快速地成长和进步。',
  Opportunities:'创造机会',
  OpportunitiesC:'视员工和客户为我们的内部和外部不可或缺的重要伙伴，除了主动预期及满足他们各项多变的需求外，并为他们提供全方位的资讯服务，给予优势、弹性和成功机会。',
  Integrity:'坚守承诺',
  IntegrityC:'坚守对顾客、合作伙伴及员工的承诺，以开放和忠诚的态度作为经营之道。力求创新的同时，并重视优良的品质的保证，不论是产品或解决方案，都为客户提供高于期望的优质产品，建立良好的品质及服务信誉。',


  Director:'非執行董事',
  Leung:'主席兼非执行董事',
  Executive:'執行董事',
  HonKarChun:'韓家振',
  LeungHonShing:'梁漢成',
  LeungChiHangDaniel:'梁智恒',

  HonKarChunZ:'董事總經理兼執行董事',
  LeungHonShingZ:'執行董事、首席財務長兼公司秘書',
  LeungChiHangDanielZ:'執行董事',
  LogisticsCentre:'物流中心',
  LogisticsCentreAddr:'香港新界沙田小瀝源安平街8號偉達中心6樓601室',



  carInfo:"汽車電子 / 信息娛樂",
  SmartIndustry:"智能工業",
  switch:"開關電源/智能照明",
  introduce:"介紹",
  introducec:"BMS是銜接新能源汽車電池包、整車控制和電機的重要紐帶，負責新能源汽車電池包的全面管理，主要包括三大核心功能，分別為電池包數據採集、電池包狀態估算、電芯單體一致性維護。",
  ProgramFeatures:"方案特色",
  photo:"框圖/評估板/相片",

  moreReferenceSolution:"更多解決方案",
  ReferenceSchemeCon:"我們致力將嶄新的開發理念融入高性價比的參考設計中, 為客戶推動具成本效益的更多解決方案。",
  ContactUsCon:"如果您對此方案感興趣，請聯繫我們瞭解專業設計意見。",
  Ltd:"中國電子產業集團有限公司",
  grid:"銷售網絡",
  btnLianxi:"請按此處聯繫我們的銷售辦事處",
  zongLtd:"總公司",
  jituanc:"集團查詢",
  xiaosouc:"銷售查詢",
  submit:"提交",
  yingyin:"應用範圍",
  laji:"智慧垃圾治理系統",
  mozu:"BLE 模組(BLUENGR)",
  mozufour:"4G 模組",
  mozunb:"NB-IoT 模組",
  bluur:"藍牙斷路器",
  gunji:"無位置檢測滾筒洗衣機",
  ipmj:"IPM 電機驅動",
  gsdianji:"高速低壓矢量電機驅動",
  gaoqudong:"高壓集成控製電機驅動",
dianboa:"智能電表電源",
shuzidian:"數字電源",
shuzikeitao:"數字可編程調光器",
ledduo:"多功能調光 LED 驅動",
nfcdu:"帶 NFC 控製 LED 調光驅動",
jituanzon:"集團總部",
xg:"香港",
jituannew:"集團新聞",
chezai:"車載電子",
xiaofeichan:"消費類電子產品",
tongxuns:"通訊網絡",
dongli:"動力總成和混合動力系統",
dongli2:"底盤和安全",
dongli3:"車身電子",
dongli4:"車載信息娛樂系統",
dongli5:"儀表板",
dongli6:"高級駕駛輔助",
dongli7:"车联网",

kechuan:"可穿戴設備",
kechuan1:"生活娛樂",
kechuan2:"電視",
kechuan3:"遊戲視頻和音頻",
kechuan4:"家用設備",
kechuan5:"廣播機頂盒",
kechuan6:"家用設備",

chuanshus:"光接入/傳輸網絡",
chuanshus1:"無線路由器",
chuanshus2:"電視傳輸",
chuanshus3:"無線局域網",
chuanshus4:"光通信",
chuanshus5:"5G應用",
ppp:"Microchip Technology Inc. 是微控製器和模擬半導體的領先供應商，為全球數千種不同的客戶應用提供低風險的產品開發、更低的系統總成本和更快的上市時間。Microchip 總部位於亞利桑那州錢德勒，提供出色的技術支持以及可靠的交付和質量。",
adipp:"ADI 的模擬、混合信號和數字信號處理 (DSP) 集成電路 (IC) 在將光、聲音、溫度、運動和壓力等現實世界現象轉換、調節和處理成電信號以實現可用於各種電子設備。ADI 是電子製造商中高性能的代名詞，我們與客戶合作以定義最佳的用戶體驗質量。",
banpp:"STMicroelectronics 是一家全球性的獨立半導體公司，在開發和提供微電子應用領域的半導體解決方案方面處於領先地位。矽和系統專業知識、製造實力、知識產權 (IP) 組合和戰略合作夥伴的無與倫比的結合使公司處於片上系統 (SoC) 技術的最前沿，其產品在實現當今的融合趨勢中發揮著關鍵作用。",
depp:"德州儀器 (TI) 是一家開發模擬 IC 和嵌入式處理器的全球半導體設計和製造公司。通過聘請世界上最聰明的人才，TI 創造了塑造技術未來的創新。今天，TI 正在幫助超過 100,000 名客戶改變未來。",
avspp:"AVX Corporation 是全球無源電子元件和互連產品行業公認的領導者。AVX Corporation 每年都會接受挑戰，幫助設計工程師利用 AVX 的尖端研究、設計專業知識和材料技術為客戶創造卓越的產品，以提高性能並降低總成本。在四大洲 17 個國家/地區的全球製造能力使 AVX 能夠在全球範圍內滿足客戶的需求。",
gonsijianjieshu:"公司簡介書",
zhuanzhujian:"專註於檢測",
waibujian:"外部視覺檢查",
wibupp:"外觀外觀檢查是檢查零件數量、內包裝、幹燥和外包裝、真空包裝是否完好、有斑紋的熱壓標記清晰並保持高度一致性、製造商的標誌位置和印刷字體（包括日期代碼和國家/地區）根據製造商的標準。",
xinpian:"芯片檢測",
xinpianpp:"芯片檢測要求元件具有相同的形狀/顏色，元件的正面和背面具有相同的光潔度，沒有劃痕。驗證二次塗層，引腳狀態，拋光標記，標記上的凸點。定位孔準確清晰，Pin/ball 顏色和形狀正常（無氧化、變形、毛刺、損壞、汙染等）。外形尺寸、引腳位置、引腳間距、引腳長度與封裝外形一致。",
bufenhogn:"部分功能測試與第三方測試機構合作",
bufenhognpp:"第三方考試中心 去蓋測試 X 射線 電氣測試 功能測試",
zhiliang:"質量控製",
zhiliangpp:"中國電子相信正規的供應渠道是基礎，我們始終堅持對每一批進出庫的產品進行檢測。所有產品必須按照公司進出庫程序進行檢測。同時，我們有權威的第三方檢驗機構作為技術支持，幫助我們完成出境貨物的樣品檢驗。",
honnrhnzz:"功能測試",
honnrhnzz2:" 驗證無源元件以確保沒有開口或缺陷；確保組件性能符合製造商的規格（電阻/電容/電感）。",


baozhuangwu:"包裝與物流",
baozhuangwu3:"測試服務的最後一步是包裝和交付，我們與其他測試項目一樣重視，所有產品必須以 ESD 標準包裝運輸，沒有缺陷和/或修改。我們認識到及時和安全地向客戶交付組件的重要性。我們提供完整的包裝和運輸服務，協助您將貨物運送到您指定的目的地。",
wome:"我們為全球行業提供電子元器件和價值服務",

err:"E-9963高精度AFE提供精準電芯電壓採集",
err2:"E-SPC58高性能MCU提供強大運算能力",
err3:"E-9963高精度AFE提供精準電芯電壓採集",

yewuxie:"業務協同化",
yewuxie2:"將車輛管理，人員管理，設施管理，任務處理，事件問題，地圖，實時通訊等業務協同，打通業務之間和各個部門的關聯，形成協同管理快速機製。",

wuchu:"無處不在的納米發電機",
wuchu2:"它的出現，徹底打破了人們對「發電機」尺寸的認識極限。納米發電機可以實現對環境中極微量機械能的收集和利用。例如，空氣或水的流動、發動機的轉動、機器的運轉、人走路時肌肉的伸展、腳踩地面，甚至呼吸引起的各種頻率的噪音，人體內的心跳或血流。身體某處壓力的輕微變化可以驅動納米發電機發電。因此，納米發電機理論為當前物聯網、傳感器網絡和大數據的實現提供了理想的動力解決方案。",
wuchu3:"目前，納米發電機可以分為三類：第一類是壓電納米發電機；第二類是摩擦納米發電機；第三類是熱釋電納米發電機。一般用於生物醫學、軍事、無線通信、無線傳感等領域。",
wuchu4:"在當前可伸縮、可穿戴電子設備快速發展的時代，柔性機械能采集裝置的研究具有非常重要的價值和意義。近年來，利用柔性材料替代高分子商業薄膜和金屬片組裝柔性摩擦納米發電機的研究成為亮點。近年來，柔性摩擦納米發電機的研究成果較多。",
wuch2:"精確測試微小信號充滿挑戰",
wuch3:"由於納米發電的技術特點，在研究過程中需要對單位面積機械能產生的電能進行測試。測試產生的電壓、微小電流和功率信號基本都是幾伏甚至幾十伏，電流一般在uA甚至nA級，功率在mW甚至uW級。小電流和功率信號難以準確測試，對測試儀器的精度和穩定性要求非常高。泰克吉時利專註於小電信號的測量。歷史上許多諾貝爾物理學獎獲得者都使用並信賴吉時利的測試儀器。在納米發電的研究中，吉時利的產品仍然是業界的首選，尤其是在小信號測試方面。",
wuch4:"測量靈敏度的理論極限取決於電路中電阻產生的噪聲。電壓噪聲與電阻、帶寬和絕對溫度乘積的平方根成正比。從圖中可以看出，源電阻限製了電壓測量的理論靈敏度，即當源電阻為1Ω的1uV信號可以準確測量時，如果信號的源電阻變為1TΩ，測量將變為不可能的。因為當源電阻為1MΩ時，1uV的測量值已經接近理論極限。這時，用普通的數字萬用表是不可能完成這類測量的。選擇合適的儀器是確保準確測試小信號的先決條件。",
wuch5:"小電流信號測試",
wuch6:"方法：采用絕緣材料納米發電技術，一般源電阻在GΩ級，測試電流在pA級，所以業界采用靜電計6514+斯坦福SR570（低噪聲電流前置放大器）+專用采集生成當前數據集合的軟件。",


whys:"什麽是理想二極管？?",
whys2:"二極管也叫晶體二極管，俗稱二極管，另外，還有最初的真空泵電子二極管；這是可以單方面傳導電流的電子元件。半導體材料二極管內部有一個PN結和兩個接線端子。這種類型的電子元件根據附加工作電壓的方向具有單向導電性。",
whys3:"一般來說，晶體二極管是通過燒製p型半導體材料和n型半導體材料製成的pn結頁。在頁面的兩側產生空間電荷層以形成靜電場。當附加工作電壓為零時，由於pn結兩側載流子濃度的不同導致外擴散電流量與構建靜電場引起的漂移電流量相同，並且處於電平衡狀態，這都是歸一化的。二極管特性。",
whys4:"二極管效率",
whys5:"二極管是最常見的電子元件中的第一個。其主要特點是單向導電，即一定量的電流只能通過二極管的一個方向。二極管的作用是整流電路和檢測電源電路。穩壓電路，各種解調電源電路。", 
whys6:"二極管原理", 
whys7:"晶體二極管是由p型半導體材料和n型半導體材料製成的pn結。在頁面的兩側產生空間電荷層並建立靜電場。當沒有發現額外的工作電壓時，pn結兩側載流子濃度不同引起的外擴散電流量與建立靜電場引起的漂移電流量相同，處於電平衡狀態。當外部有正向工作電壓參考點時，外部靜電場和建立的靜電場的相互抑製作用增加了載流子的外部擴散電流量，從而產生了正向電流量。當存在反方向的外部工作電壓參考點時，外部靜電場和構造靜電場得到有效加強，與反方向參考點工作電壓值無關的反向飽和電流I0為在相反方向的工作電壓範圍內產生。當反方向的附加工作電壓高到必要水平時，pn結空間電荷層中的電場強度超過臨界點，導致載流子在整個過程中生長，導致許多電子器件出現空化現象, 導致一個非常大的標度值反向的熱擊穿電流量稱為二極管的熱擊穿條件。pn結的反向熱擊穿可分為齊納熱擊穿和雪崩擊穿。", 
whys8:"二極管基本參數", 
whys9:"是指二極管長期連續工作時所允許的較大的正向平均電流，其值與PN結的總面積和外接熱管的散熱標準有關。由於電流量根據水管的時間使模具變熱，溫度升高，當溫度超過允許下限時（矽管最高141，鍺管最高90），模具會過熱而損壞。因此，在熱管散熱標準的要求下，二極管應用不一定要超過二極管的較大整流電流。例如，常見的 IN4001-4007 鍺二極管在正向工作時的額定電流為 1A。", 
whys11:"當加在二極管兩側的反方向工作電壓高到必要值時，水管會發生熱擊穿，失去單邊導電工作能力。為保證應用安全，需要反向的最大工作頻率值。例如IN4001二極管的反向耐壓測試為50V，IN4007的反向耐壓測試為1000V。", 
whys22:"反向電流量是指在二極管常溫（25℃）和反向最大工作電壓的作用下，反向通過二極管的電流量。反方向的電流越小，水管的單向電導率越高。特別要註意的是，相反方向的電流量與溫度密切相關。大約溫度每升高 10°C，相反方向的電流量就會增加數倍。比如2AP1鍺二極管，25℃時反方向電流250uA，溫度上升到35℃，反方向電流上升到500uA，以此類推，75℃，它的反方向電流已經高達8mA，不僅失去了單向導電的特性，還會因過熱而損壞水管。又如2CP10型矽二極管，25℃時反方向電流僅為5uA，升溫至75℃時反方向電流僅為160uA。因此，矽二極管在高溫下比鍺二極管具有更好的可靠性。", 

shoujirend:"收集個人資料聲明",
shoujirend2:"現今電子產業的發展日新月異，標準要求也越來越高，而每位客戶對於技術要求也不盡相同。集團提供多元化的產品線，滿足各行各業所需，包括汽車電子、信息娛樂、物聯網應用、電機驅動、開關電源及智能照明等等應有盡有。從而發掘高性價比的參考設計，讓客戶擁有靈活多變的選擇，以強大的適應性配合市場，率先佔領行業先機。",
shoujirend3:"與其他資料之間的連結",
shoujirend4:"Cookie政策",
shoujirend5:"有一種稱為「Cookie」的技術，可用於為您提供量身打造的網站資訊。Cookie 是一組數據元素，網站可以傳送到您的瀏覽器，隨後儲存在您的系統中。某些中國電子頁面可能會使用 Cookie，以便在您返回我們網站時，我們能為您提供更好的服務。您可以將瀏覽器設定為在收到 Cookie 時通知您，從而讓您有機會決定是否接受它。",
shoujirend6:"如果我們提供了連接到第三方網站的任何連結，它只是作為一項通向相應第三方網站所有者的便利措施。經由本網站提供的其他第三方網站連 結，並未處於中國電子的控管之中，對於任何此類連結網站的內容、隱私慣例、任何連結或連結程序，中國電子概不負責。",

diysho:"第一手資料",
diysho2:"中國電子(集團)有限公司網站內的所有第一手資料，均屬本集團版權所有，未經本集團許可，不得修改、散佈、下載及刊出。",
diysho3:"第三方資料",
diysho4:"第一手資料中國電子網站內的所有第一手資料，均屬本集團版權所有，未經本集團許可，不得修改、散佈、下載及刊出。第三方資料網站中含有由第三方資料提供及從其他參考資料或來源獲取的資料，此類涉及任何人士、產品或服務的資料，不得視為本集團推薦或認可。本集團不會負責此類資料的最新性及準確性，因此本集團對該等資料不承擔任何責任。使用及依賴該等資料，風險將由閣下自負。",
diysho5:"本網站的連接", 
diysho6:"本網站的連接可將閣下連往其他網址，而閣下承認並同意，本集團對此類連接網址所提供的任何資訊的準確性或有效性，不負任何責任。", 
names:"姓名",
gongsimign:"公司名稱",
xuni:"訊息",
emaiti:"電子郵件提醒",
emiss:"歡迎使用中國電子工業集團有限公司郵件提醒服務我們的系統會自動向您發送中國電子工業集團有限公司的公告",
souxun:"搜尋...",
banquansuo:"版權所有©中國電子工業集團有限公司保留所有權利。",



...zhLocale
}


