import axios from "axios";
import qs from "qs";
import { Message } from "element-ui";



// axios.defaults.baseURL = ''  //正式
// axios.defaults.baseURL = 'http://bjapi.ycgos.com' //测试

// //post请求头
// axios.defaults.headers.post["Content-Type"] ="application/x-www-form-urlencoded;charset=UTF-8";
// //设置超时
// axios.defaults.timeout = 10000;

// axios.interceptors.request.use(
//   config => {
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

// axios.interceptors.response.use(
//   response => {
//     if (response.status == 200) {
//       return Promise.resolve(response);
//     } else {
//       return Promise.reject(response);
//     }
//   },
//   error => {
//     alert(`异常请求：${JSON.stringify(error.message)}`)
//   }
// );
// export default {
//   post(url, data) {
//     return new Promise((resolve, reject) => {

//       axios({
//         method: 'post',
//         url,
//         data: qs.stringify(data),
//       })
//         .then(res => {
//           resolve(res.data)
//         })
//         .catch(err => {
//           reject(err)
//         });
//     })
//   },

//   get(url, data) {
//     return new Promise((resolve, reject) => {
//       axios({
//         method: 'get',
//         url,
//         params: data,
//       })
//         .then(res => {
//           resolve(res.data)
//         })
//         .catch(err => {
//           reject(err)
//         })
//     })
//   }
// };
//  --------------------------------start------------------------------------
axios.defaults.headers.post["Content-Type"] ="application/x-www-form-urlencoded;charset=UTF-8";
// 创建axios实例
const service = axios.create({
  // axios.defaults.baseURL = ''  //正式
  //baseURL: 'http://bjapi.ycgos.com',
  baseURL: 'http://bjapi.ceic.co',
  // 超时
  timeout: 10000,
});
// request拦截器
service.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    console.log(response.status);
    if (response.status == 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    // alert(`异常请求：${JSON.stringify(error.message)}`)
    Message({
      message: `异常请求：${JSON.stringify(error.message)}`,
      type: "error"
    });
  }
);

export default service;
