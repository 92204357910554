import Vue from 'vue'
import Vuex from 'vuex'
import {setTabSelect, getTabSelect} from '../utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tabSelect: getTabSelect() || '1', // Nav组件默认选中项
  },
  getters: {
  },
  mutations: {
    setTabSelet(state, payload) {
      console.log('当前tab选中项' + payload);
      state.tabSelect = payload + ''
      setTabSelect(payload)
    }
  },
  actions: {
  },
  modules: {
  }
})
