import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Nav from './components/Nav.vue'
import Footer from './components/Footer.vue'
import mySwiper from './components/swiper.vue'
import ElementUI, {Message} from 'element-ui';
import VueI18n from 'vue-i18n'  //引入vue-i18n
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(VueI18n)
Vue.use(ElementUI)
Vue.use(Antd)
Vue.prototype.$message = Message
Vue.config.productionTip = false
import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles/global.css'
import 'swiper/css/swiper.css'
import 'swiper/js/swiper.min'
Vue.component('Nav', Nav)
Vue.component('Footer', Footer)
Vue.component('mySwiper', mySwiper)

let mixin = {
  methods: {
     goDetail() {
      this.$router.push('/recruitment')
    }
  }
}

Vue.mixin(mixin)
/*---------使用语言包-----------*/
import i18n from './assets/common/lang/index'
/* eslint-disable no-new */
new Vue({
  mixins: [mixin],
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
