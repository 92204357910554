const tabSelect = 'tab_select'

export function getTabSelect() {
  return sessionStorage.getItem(tabSelect)
}

export function setTabSelect(select) {
  return sessionStorage.setItem(tabSelect, select)
}

export function removeTabSelect() {
  return sessionStorage.removeItem(tabSelect)
}
